import { ColorSettingsDto } from '@slabcode/kiosks-core/dtos/settings/brand';

export function generateRootCss(colors: ColorSettingsDto): void {
  const root = document.documentElement;

  root.style.setProperty('--kiosk-primary', colors.primary);
  root.style.setProperty('--kiosk-secondary', colors.secondary);
  root.style.setProperty('--kiosk-tertiary', colors.tertiary);
  root.style.setProperty('--kiosk-background', colors.background ?? '#fff');
}
