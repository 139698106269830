<script setup lang="ts">
import { ButtonType } from '@/common/types';

const props = defineProps({
  color: {
    type: String as PropType<ButtonType>,
    required: false,
    default: 'default',
  },
  type: {
    type: String as PropType<'button' | 'submit'>,
    required: false,
    default: 'button',
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  textSize: {
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
});

const { color, textSize } = toRefs(props);
const { buttonClasses } = useUiComposable();

const textClass = computed(() => {
  switch (textSize.value) {
    case 'large':
      return 'py-6 min-w-[444px] leading-[44px] text-[52px]';
    case 'small':
      return 'py-4 px-6 !border-2 leading-8 text-[26px]';
    case 'extra-small':
      return '!py-2.5 px-4 !border-2 leading-6 text-xl';
    default:
      return 'py-5 max-w-[444px] leading-10 text-[40px]';
  }
});

defineEmits(['click']);
</script>

<template>
  <button
    :type="type"
    class="btn"
    :disabled="disabled"
    :class="[textClass, buttonClasses[color], props.class, { 'py-[30px]': !icon.length && textSize !== 'small' }]"
    @click="$emit('click')"
  >
    <span
      v-if="icon.length"
      class="icon block mr-4"
      :class="`icon-${icon}`"
    />
    <slot />
  </button>
</template>

<style scoped>
.btn {
  @apply h-auto flex flex-nowrap px-8 border-[3px] items-center justify-center uppercase rounded-[100px] shadow-md disabled:opacity-50;
}

.hide-button-icon .icon {
  @apply hidden;
}
</style>
