<script setup lang="ts">
import { PropType } from 'vue';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';

const props = defineProps({
  item: {
    type: Object as PropType<HubsterOrderModifier>,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  decimals: {
    type: Number,
    required: true,
  },
  isChildRow: {
    type: Boolean,
    required: true,
  },
  paddingLeft: {
    type: Number,
    required: false,
    default: 16,
  },
});

const { item, currency, decimals } = toRefs(props);

const currencyValue = (value: number) => formatCurrency(
  currency.value,
  value,
  decimals.value,
);

const hasChildren = computed(() => !!props.item.modifiers?.length);
const childPadding = computed(() => (hasChildren.value ? (props.paddingLeft + 16) : props.paddingLeft));
</script>

<template>
  <tr class="table-columns text-neutral-400">
    <td
      class="text-start border-l-2 border-dotted"
      :style="{ paddingLeft: `${paddingLeft}px` }"
    >
      {{ item.name }}
    </td>
    <td class="text-center">
      {{ item.quantity }}
    </td>
    <td class="text-end">
      {{ currencyValue(item.price ?? 0) }}
    </td>
  </tr>

  <template v-if="hasChildren">
    <OrderCheckoutRow
      v-for="child in item.modifiers"
      :key="child.id"
      :item="child"
      :is-child-row="hasChildren"
      :currency="currency"
      :decimals="decimals"
      :padding-left="childPadding"
    />
  </template>
</template>

<style scoped>
.table-columns > td {
  @apply text-2xl leading-7 tracking-[0.5px];
}
</style>
