import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { HubsterPerson } from '@slabcode/hubster-models/hubster/common/person';
import { HubsterFulfillmentInfo } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { Cart } from '@/common/interfaces';
import { CollapsableModifierGroup, KioskCartItem } from '@/modules/orders/interfaces';

const INITIAL_STATE: Required<Cart> = {
  itemToUpdate: null,
  itemToUpdateIndex: -1,
  items: [],
  customer: {} as HubsterPerson,
  customerPayments: [],
  fulfillmentInfo: {} as HubsterFulfillmentInfo,
  updatedModifiers: {},
};

export const useCartStore = defineStore('cart', {
  state: (): Required<Cart> => structuredClone(INITIAL_STATE),

  getters: {
    firstItem: (state) => state.items.at(0),
  },

  actions: {
    /**
     * Creates a local ID for a KioskCartItem.
     * The local ID is generated by concatenating the item ID with a string representation of its modifiers.
     * @param item - The KioskCartItem for which to create the local ID.
     * @returns The local ID for the item.
     */
    createLocalId(item: KioskCartItem) {
      const index = this.items.length;
      const modifiersStr = item.modifiers
        ?.map((modifier) => `${index}:${modifier.id}?q=${modifier.quantity}`)
        .join('/') ?? '';

      return item.id + modifiersStr;
    },

    /**
     *Add item to cart and define the api structure
     *
     * @param {KioskCartItem} item element that will be add to cart
     */
    addCartItem(item: KioskCartItem, upgrade = false) {
      const newItem: KioskCartItem = {
        ...item,
        /* Take into account that the item is unique while the modifiers are the same,
         * if have different modifiers should be create a new item
         */
        localId: this.createLocalId(item),
        identifier: uuidv4(),
      };

      const newItemString = JSON.stringify(newItem);

      const alreadyExist = this.items.find((it) => {
        const expectedItem = { ...it, quantity: item.quantity };
        return JSON.stringify(expectedItem) === newItemString;
      });

      // If there are an item with that id is the same item but its quantity increase
      if (alreadyExist) {
        alreadyExist.quantity += 1;
        return;
      }

      if (upgrade) {
        this.items = [newItem, ...this.items];
        return;
      }

      this.items = [...this.items, newItem];
    },

    /**
     * Save the cart item form state to edit him using this reference
     *
     * @param item - The cart item to add.
     * @param modifierGroups - The modifier groups to save.
     */
    addCartItemAndSaveModifier(item: KioskCartItem, modifierGroups: CollapsableModifierGroup[]) {
      const localId = this.createLocalId(item);
      this.saveModifierGroup(modifierGroups, localId);
      this.addCartItem(item);
    },

    /**
     * Update item in the cart for new one
     *
     * @param {KioskCartItem} item element that will be update to cart
     */
    updateItem(item: KioskCartItem) {
      this.items[this.itemToUpdateIndex] = item;
      // Reset update index
      this.itemToUpdateIndex = -1;
      // this.items = this.items.map((it) => (it.id === item.id ? item : it));
    },

    /**
     * update item quantity in cart
     * @param {number} index is the index that will be updated
     * @param {number} quantity is the new quantity for that product
     */
    updateItemQuantity(index: number, quantity: number) {
      this.items[index].quantity = quantity;
    },

    /**
     * Delete an existing item
     *
     * @param {number} index - Cart item index
     */
    deleteItem(index: number) {
      this.items = this.items.filter((_, i) => i !== index);
    },

    // getTotal() {
    //   return this.items.reduce((total, currentItem) => {
    //     const { modifiers, quantity, price } = currentItem;
    //     if (!modifiers) return total + (price! * quantity);
    //     // Has modifiers
    //     const modifiersTotal = modifiers.reduce((t, mod) => t + (mod.price! * mod.quantity), 0);
    //     return total + ((price! + modifiersTotal) * quantity);
    //   }, 0);
    // },

    getTotal(): number {
      // const productPrice = this.currentProduct.price.amount;
      const getModPrice = (children: HubsterOrderModifier[], price: number) =>
        children.reduce((acc, curr) => {
          let childrenPrice = 0;
          if (curr.modifiers && curr.modifiers.length > 0) childrenPrice = getModPrice(curr.modifiers!, 0);

          return acc + ((curr.price! + childrenPrice) * curr.quantity);
        }, price);

      return this.items.reduce((acc, curr) => acc + getModPrice(curr.modifiers, curr.price) * curr.quantity, 0);
    },

    /**
     * Add item that will be updated in customization
     *
     * @param {(KioskCartItem | null)} item this will be the reference to update
     */
    addItemToUpdate(item: KioskCartItem | null, index: number) {
      this.itemToUpdate = item;
      this.itemToUpdateIndex = index;
    },

    /**
     * Saves the modifier group for a given local ID.
     *
     * @param modifierGroup - The modifier group to be saved.
     * @param localId - The local ID of the modifier group.
     */
    saveModifierGroup(modifierGroup: CollapsableModifierGroup[], localId: string) {
      this.updatedModifiers[localId] = modifierGroup;
    },

    /**
     * Retrieves the modifier group for a given cart item.
     * @param localId - The local ID of the modifier group.
     * @returns The modifier group associated with the cart item.
     */
    getModifierGroup(localId: string) {
      return this.updatedModifiers[localId];
    },
    /**
     * Clean the cart
     */
    clearCart() {
      this.$patch(($state) => {
        Object.assign($state, { ...INITIAL_STATE, updatedModifiers: {} });
      });
    },
  },
});

export default useCartStore;
