import { RouteLocationNamedRaw } from 'vue-router';
import { StepName } from '@/common/routes/routeNames';

// eslint-disable-next-line max-lines-per-function
export const useStepStore = defineStore('step', () => {
  const route = useRoute();
  const router = useRouter();
  const metadataStore = useMetadataStore();
  const orderTypeStore = useOrderTypeStore();
  const currentRoute = ref<StepName>(route.name as StepName);

  const orderTypeNext = computed(() => {
    if (orderTypeStore.isPickupOrder) return StepName.ORDER_CATEGORIES;
    return metadataStore.hideTableNumber ? StepName.ORDER_CATEGORIES : StepName.ORDER_TABLE;
  });

  const orderCategoriesPrev = computed(() => {
    if (orderTypeStore.tableNumber.length) return StepName.ORDER_TABLE;
    return StepName.ORDER_TYPE;
  });

  const nextConfig = computed(() => ({
    [StepName.WELCOME]: StepName.ORDER_TYPE,
    [StepName.ORDER_OPTIONS]: StepName.ORDER_TYPE,
    [StepName.ORDER_TYPE]: orderTypeNext.value,
    [StepName.ORDER_TABLE]: StepName.ORDER_CATEGORIES,
    [StepName.ORDER_CATEGORIES]: StepName.PRODUCTS,
    [StepName.PRODUCTS]: StepName.PRODUCT_CUSTOMIZATION,
    [StepName.PRODUCT_CUSTOMIZATION]: StepName.PRODUCTS,
    [StepName.ORDER_CART]: StepName.ORDER_CHECKOUT,
    [StepName.ORDER_CHECKOUT]: StepName.WELCOME,
  }));

  const prevConfig = computed(() => ({
    [StepName.WELCOME]: StepName.WELCOME,
    [StepName.ORDER_OPTIONS]: StepName.WELCOME,
    [StepName.ORDER_TYPE]: StepName.WELCOME,
    [StepName.ORDER_TABLE]: StepName.ORDER_TYPE,
    [StepName.ORDER_CATEGORIES]: orderCategoriesPrev.value,
    [StepName.PRODUCTS]: StepName.ORDER_CATEGORIES,
    [StepName.PRODUCT_CUSTOMIZATION]: StepName.PRODUCTS,
    [StepName.ORDER_CART]: StepName.PRODUCTS,
    [StepName.ORDER_CHECKOUT]: StepName.ORDER_CART,
  }));

  const nextRouteName = computed(() => nextConfig.value[currentRoute.value]);
  const prevRouteName = computed(() => prevConfig.value[currentRoute.value]);

  function goToNext(to?: RouteLocationNamedRaw) {
    const name = to?.name as StepName;
    currentRoute.value = name ?? nextRouteName.value;
    router.push({ name: currentRoute.value, params: to?.params });
  }

  function goBack(to?: RouteLocationNamedRaw) {
    const name = to?.name as StepName;
    currentRoute.value = name ?? prevRouteName.value;
    router.push({ name: currentRoute.value, params: to?.params });
  }

  function $reset() {
    currentRoute.value = StepName.WELCOME;
    // TODO: Remove router history
    router.push({ name: currentRoute.value });
  }

  return {
    currentRoute,
    nextRouteName,
    prevRouteName,
    orderTypeNext,
    goToNext,
    goBack,
    $reset,
  };
});
