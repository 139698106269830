import { acceptHMRUpdate } from 'pinia';
import { InactivitySettingsDto } from '@slabcode/kiosks-core/dtos/settings/brand';

// TODO: Move to (Main / Orders) module folder and create constant and interface
interface State extends InactivitySettingsDto {
  currentKey: keyof InactivitySettingsDto;
  defaultTimers: InactivitySettingsDto;
}

const INITIAL_STATE: State = {
  screen: 10,
  timer: 30,
  currentKey: 'timer',
  defaultTimers: { screen: 10, timer: 30 },
};

export const useInactiveStore = defineStore('inactive', {
  state: () => {
    const metadataStore = useMetadataStore();
    if (!metadataStore.inactivity) return { ...INITIAL_STATE };

    return {
      ...INITIAL_STATE,
      defaultTimers: { ...metadataStore.inactivity },
    };
  },

  actions: {
    /**
     * Set new value to specific state key and override its default value.
     * @param {keyof InactivitySettingsDto} key - Key to update
     * @param {number} value - Value to assign
     */
    changeInactiveTime(key: keyof InactivitySettingsDto, value: number) {
      this[key] = value;
      this.defaultTimers[key] = value;
    },

    /**
     * Update current state with new timer values
     * @param {InactivitySettingsDto} values - New values
     */
    setInactiveValues(values: InactivitySettingsDto): void {
      this.$patch({
        ...values,
        defaultTimers: { ...values },
      });
    },

    /**
     * Start making discount to currentKey in state every 1 second
     */
    async startCountdown(): Promise<void> {
      if (this[this.currentKey] < 1) return;

      while (this[this.currentKey] > 0) {
        // TODO: Validate memory leak
        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise((res) => setTimeout(res, 1000));
        // Make discount
        this[this.currentKey] -= 1;
      }
    },

    /**
     * Set new value to 'currentKey' and trigger 'setInactiveValues'
     * method with 'defaultTimers' value to restore default values.
     * @param {keyof InactivitySettingsDto} key - New key
     */
    resetCountdown(key: keyof InactivitySettingsDto) {
      this.currentKey = key;
      // Reset store
      this.setInactiveValues(this.defaultTimers);
      // Uncomment next two lines for long test cases
      // const { screen } = { ...this.defaultTimers };
      // this.setInactiveValues({ timer: 90000, screen });
    },
  },
});

// https://pinia.vuejs.org/cookbook/hot-module-replacement.html
// This line will be added to all stores to make right hydrate and provide better developer experience with Pinia.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInactiveStore, import.meta.hot));
}
