<script lang="ts" setup>
const router = useRouter();
const cartStore = useCartStore();
const menuStore = useMenuStore();
const modalStore = useModalStore();
const metadataStore = useMetadataStore();

const { menu } = storeToRefs(menuStore);
const { priceDecimals } = storeToRefs(metadataStore);

const total = computed(() => {
  const { firstItem } = cartStore;

  if (!firstItem || !firstItem.id || !menu.value?.items) return 0;

  // const currency = menu.value.items[firstItem.id]?.price?.currencyCode ?? 'USD';
  const currency = 'USD';

  return formatCurrency(currency, cartStore.getTotal(), priceDecimals.value);
});

function goToCart() {
  router.push({ name: 'OrderCartView' });
}

function back() {
  modalStore.openModal();
}

</script>

<template>
  <div v-if="cartStore.getTotal() !== 0" class="flex items-center justify-between h-20 p-4 bg-primary-900 animate__animated animate__fadeInUp">
    <KioskButton
      text-size="small"
      class="flex gap-1 items-center justify-center w-[150px] h-14 text-3xl"
      @click="back()"
    >
      {{ $t('BACK') }}
    </KioskButton>

    <span>
      {{ $t('TOTAL') }} <b>&nbsp;&nbsp;{{ total }}</b>
    </span>

    <KioskButton
      class="flex gap-1 items-center justify-center w-[150px] h-14 text-3xl"
      color="primary"
      text-size="small"
      @click="goToCart()"
    >
      {{ $t('TIMELINE.CONFIRM') }}
    </KioskButton>
  </div>
</template>

<style scoped>
span {
@apply text-white p-4 flex justify-center items-center text-3xl
}
</style>
