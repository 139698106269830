<!-- eslint-disable vue/max-len -->
<script lang="ts" setup>
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { KioskCartItem, ModifierUpdate } from '@/modules/orders/interfaces';
import { useGTMEventsComposable } from '@/common/composables/gtmEvents';

const router = useRouter();
const menuStore = useMenuStore();
const cartStore = useCartStore();
const showAlcohol = ref(false);
const metadataStore = useMetadataStore();
const modifiersStore = useModifierStore();
const { alcohol } = storeToRefs(menuStore);
const { itemToUpdate } = storeToRefs(cartStore);
const { triggerAddToCart, triggerGoBack, triggerEditVariation, triggerScaleUpItem } = useGTMEventsComposable();
const { counterStyle, priceDecimals, showPaginatedMG } = storeToRefs(metadataStore);
const { currentIndexModifier, currentOpenModifier, currentSelectedItems, isEdit, modifiers, selectedItems, hasUpgrade, priceWithModifiers } = storeToRefs(modifiersStore);

const route = useRoute();

const isFullAnimation = ref(false);
const selectedModifier = ref<ModifierUpdate>();
const currentItem = ref<HubsterMenuPublishItem | null>(null);
const categoryId = ref<string>('');
const showCatalogModal = ref<boolean>(false);
const showUpgradeModal = ref<{
  show: boolean,
  name: string,
  description: string,
}>({
  show: false,
  name: '',
  description: '',
});

const filteredModifiers = computed(
  () => modifiers.value.filter((m) => !m.name.includes('[DO NOT SHOW]')),
);

const isProductValid = computed(() => {
  if (!showPaginatedMG.value) {
    if ((currentIndexModifier.value + 1) !== filteredModifiers.value.length) return false;
    return filteredModifiers.value.every((m) => m.valid);
  }

  return filteredModifiers.value[currentIndexModifier.value].valid;
});

function checkUpgraded() {
  if (hasUpgrade.value) {
    const hasBeenUpgrated = selectedItems.value.some((i) => i.groupId === hasUpgrade.value?.id);

    if (hasBeenUpgrated && currentItem.value) {
      // Trigger GTM event
      triggerScaleUpItem({
        item: currentItem.value,
        isEdit: isEdit.value,
        value: hasUpgrade.value.items[0].price ?? 0,
        identifier: itemToUpdate.value ? itemToUpdate.value.identifier! : cartStore.items.at(-1)?.identifier ?? '',
      });
    }
  }
}

function handleSave(item: HubsterMenuPublishItem) {
  const modifierGroup = modifiersStore.modifiersGroups;
  const product: KioskCartItem = {
    ...item,
    price: item.price.amount,
    quantity: 1,
    modifiers: modifiersStore.selectedItems,
    note: item.photoIds[0],
    categoryId: categoryId.value,
    categoryName: menuStore.currentCategory?.name ?? '',
    localId: '',
  };

  cartStore.addCartItemAndSaveModifier(product, modifierGroup);
  const identifier = cartStore.items.at(-1)?.identifier ?? '';

  checkUpgraded();

  triggerAddToCart({
    product,
    modifiers: modifiersStore.selectedItems,
    totalPrice: modifiersStore.priceWithModifiers,
    category: menuStore.currentCategory!,
    quantity: 1,
    section: route.name,
    identifier,
  });

  triggerEditVariation({
    history: modifiersStore.history,
    category: menuStore.currentCategory!,
    identifier,
    isEdit: false,
    product: modifiersStore.currentProduct,
  });
}

function handleUpdate(item: HubsterMenuPublishItem) {
  const product: KioskCartItem = {
    ...item,
    localId: cartStore.itemToUpdate!.localId,
    price: item.price.amount,
    quantity: itemToUpdate.value?.quantity ?? 1,
    categoryId: categoryId.value,
    categoryName: menuStore.currentCategory?.name ?? '',
    modifiers: modifiersStore.selectedItems,
    note: item.photoIds[0],
  };

  cartStore.updateItem(product);
  const identifier = itemToUpdate.value?.identifier ?? '';

  checkUpgraded();

  triggerAddToCart({
    product,
    section: route.name,
    modifiers: modifiersStore.selectedItems,
    totalPrice: modifiersStore.priceWithModifiers,
    category: menuStore.currentCategory!,
    quantity: product.quantity,
    identifier,
    operation: 'EDIT',
  });

  triggerEditVariation({
    history: modifiersStore.history,
    category: menuStore.currentCategory!,
    identifier,
    isEdit: true,
    product: modifiersStore.currentProduct,
  });
}

function goToCatalog(isBack = false) {
  showCatalogModal.value = false;
  // Make redirect
  router.push({ name: 'OrderProductsView' });
  // Trigger GTM event
  if (isBack) triggerGoBack(route.name);
}

/**
 * complete the saving and detect if should be save or update the state
 * and redirect
 * @param {HubsterMenuPublishItem} item is the current item who has that the current modifiers
 */
function completeSaving(item: HubsterMenuPublishItem) {
  if (!isEdit.value) {
    handleSave(item);
    goToCatalog();
  } else {
    handleUpdate(item);
    // Return to cart
    router.push({ name: 'OrderCartView' });
  }
}

/**
 * Detect if the item has upgrade option to show the modal or save normally
 */
function saveModifiers() {
  const upgradeItem = modifiersStore.hasUpgrade;

  if (upgradeItem) {
    showUpgradeModal.value = {
      show: true,
      // @ts-ignore there are not exist in the slabcode library yet
      description: upgradeItem.items[0].description,
      name: upgradeItem.items[0].name!,
    };
    return;
  }

  const { value } = currentItem;
  if (!value) return;

  completeSaving(value);
}

function validateUpdating(item: KioskCartItem | null) {
  if (!item) return;
  const editableModifierGroup = cartStore.getModifierGroup(item.localId);
  modifiersStore.loadModifierGroup(editableModifierGroup);
}

function getItemAndModifiers(isEditVal: boolean) {
  const itemId = route.params.itemId as string;
  categoryId.value = route.params.categoryId as string;

  if (!menuStore.menu) return;
  currentItem.value = menuStore.menu.items[itemId];

  if (!isEditVal) {
    const modifierItems = menuStore.getModifiersByItem(currentItem.value.id, !isEditVal);
    modifiersStore.setModifiers(modifierItems);
  }
  modifiersStore.setCurrentProduct(currentItem.value);
}

/**
 * Add the upgrade modifier to the item and continue the saving normally
 */
function acceptUpgrade() {
  modifiersStore.addUpgradeModifier();

  const { value } = currentItem;
  if (!value) return;

  completeSaving(value);
}

/**
 * Keep saving normally and hide de upgrade modal
 */
function denyUpgrade() {
  const { value } = currentItem;
  if (!value) return;
  completeSaving(value);
  showUpgradeModal.value = {
    show: false,
    description: '',
    name: '',
  };
}

function updateModifier(updateInfo: ModifierUpdate) {
  const hasAlcohol = updateInfo.data.item.skuDetails.containsAlcohol;

  if (hasAlcohol && !alcohol.value.checked) {
    showAlcohol.value = true;
    // Set item
    selectedModifier.value = updateInfo;
    return;
  }

  modifiersStore.updateCamilo(updateInfo);
  // Reset selectedModifier
  selectedModifier.value = undefined;
}

function updateCollapse({ index, value }: { index: number, value: boolean }) {
  // if (modifiers.value.length > 0) {
  //   modifiersStore.modifiersGroups[index].collapse = value;
  // }
  modifiersStore.modifiersGroups[index].collapse = value;
}

/**
 * Make scroll to top in 'showPaginatedMG' is false,
 * and calls 'changeCurrentModifier' action from store.
 * @param {number} index - New index
 */
function goToIndexModifier(index: number) {
  if (!showPaginatedMG.value) {
    const firstItem = document.getElementById('item-0');
    if (firstItem) firstItem.scrollIntoView();
  }

  // Update Open modifier
  modifiersStore.changeCurrentModifier(index);
}

function cancelSelection() {
  const backRoute = isEdit.value ? 'OrderCartView' : 'OrderProductsView';
  // Make redirect
  router.push({ name: backRoute });
  // Trigger GTM event
  triggerGoBack(route.name);
}

function disableAlcohol() {
  showAlcohol.value = false;
  menuStore.setAlcoholValue({ allow: false, checked: true });
  // Reset modifier selection
  selectedModifier.value = undefined;
}

function confirmAlcohol() {
  showAlcohol.value = false;
  menuStore.setAlcoholValue({ checked: true });
  // Make update
  updateModifier(selectedModifier.value!);
}

// TODO: It's similar than OrderDeepModifiers component (Make utility)
function valueChanged(updateInfo: ModifierUpdate) {
  const { item, quantity, type } = updateInfo.data;
  const isAddingItem = item.quantity < quantity;
  const hasMaximumSelected = currentSelectedItems.value === currentOpenModifier.value?.maximumSelections;

  if (hasMaximumSelected && isAddingItem) {
    // Show full animation
    isFullAnimation.value = true;
    setTimeout(() => { isFullAnimation.value = false; }, 500);

    if (type === 'checkbox') return;
  }
  // Make expected update
  updateModifier(updateInfo);
}

function beforeMounted() {
  // The order is important, first need to validate if isEdit to define if needs default modifiers
  if (itemToUpdate.value) {
    isEdit.value = true;
    // Here update the values with the item to update and not by default
    validateUpdating(itemToUpdate.value);
  }

  // Here define the default values if its not edit
  getItemAndModifiers(isEdit.value);
  modifiersStore.history.push(...selectedItems.value);
}

function beforeUnmount() {
  cartStore.addItemToUpdate(null, -1);
  modifiersStore.$reset();
}

onBeforeMount(() => beforeMounted());
onBeforeUnmount(() => beforeUnmount());
</script>

<template>
  <section
    class="h-screen customization"
    :class="{ '!h-[calc(100vh-112px)]': !showPaginatedMG }"
  >
    <div
      v-if="currentItem"
      class="flex flex-col items-center pb-5 mt-4 border-b shadow-md current-item border-neutral-200/40"
    >
      <KioskImage
        :src="currentItem.photoIds[0]"
        :alt="currentItem.name"
        hide-border
        class="w-80"
      />

      <h4 class="my-5 text-4xl font-bold product-name">
        {{ currentItem.name }}
      </h4>

      <p class="w-2/3 text-3xl text-center item-description text-neutral-400">
        {{ currentItem.description }}
      </p>
    </div>

    <template v-if="currentOpenModifier">
      <OrderCustomizationStepItems
        v-if="showPaginatedMG"
        :is-edit="isEdit"
        :modifier="currentOpenModifier"
        :step-index="currentIndexModifier"
        :selected-items="selectedItems"
        :total-steps="filteredModifiers.length"
        :is-current-valid="isProductValid"
        :modifier-selections="currentSelectedItems"
        :price-decimals="priceDecimals"
        :counter-style="counterStyle"
        :show-full-animation="isFullAnimation"
        @cancel="goToCatalog(true)"
        @save="saveModifiers()"
        @go-to-step="goToIndexModifier($event)"
        @counter-changed="valueChanged($event)"
      />

      <OrderCustomizationDropdownItems
        v-else
        :modifiers="filteredModifiers"
        :modifier-index="currentIndexModifier"
        :selected-items="selectedItems"
        :is-current-valid="isProductValid"
        :is-edit="isEdit"
        :product-price="priceWithModifiers"
        :price-decimals="priceDecimals"
        :counter-style="counterStyle"
        :show-full-animation="isFullAnimation"
        @cancel="cancelSelection()"
        @save="saveModifiers()"
        @go-to-step="goToIndexModifier($event)"
        @update-collapse="updateCollapse($event);"
        @counter-changed="valueChanged($event)"
      />
    </template>
  </section>

  <ConfirmModal
    v-if="showUpgradeModal.show"
    :icon="hasUpgrade?.items[0].image"
    :title="showUpgradeModal.name"
    :message="showUpgradeModal.description"
    :local-modal="true"
    :success-button-label="$t('ORDER.ACCEPT_UPGRADE')"
    :deny-button-label="$t('ORDER.DENY_UPGRADE')"
    @on-close="denyUpgrade()"
    @on-ok="acceptUpgrade()"
  />

  <AlcoholConfirm
    v-if="showAlcohol"
    modifiers
    @cancel="disableAlcohol()"
    @confirm="confirmAlcohol()"
  />
</template>

<style scoped>
.customization {
  @apply flex flex-col overflow-hidden;
}

.shake {
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.customization-button {
  @apply flex gap-1 items-center justify-center w-[308px] !h-16 py-3 text-3xl;
}
</style>
