import { HubsterOrderItem, HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';

export function getCartItemPrice(item: HubsterOrderItem): number {
  const { modifiers, price, quantity } = item;

  const getModPrice = (children: HubsterOrderModifier[], modPrice: number) => children.reduce((acc, curr) => {
    let childrenPrice = 0;
    if (curr.modifiers && curr.modifiers.length > 0) childrenPrice = getModPrice(curr.modifiers!, 0);

    return acc + ((curr.price! + childrenPrice) * curr.quantity);
  }, modPrice);

  if (!modifiers || !price) return 0;

  return getModPrice(modifiers!, price!) * quantity;
}
