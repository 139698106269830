import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { ModifierGroup } from '../../modules/orders/interfaces';

/**
 * Parse from local structure to API structure
 *
 * @export
 * @param {ModifierGroup[]} group
 */
export function parseOrder(group: ModifierGroup[]): HubsterOrderModifier[] {
  return group
    .map((modifier) => modifier.items.map((item) => {
      const newItem = {
        id: item.id,
        quantity: item.quantity,
        groupId: item.groupId,
        name: item.name,
        price: item.price,
        tax: item.tax,
        section: modifier.name,
        level: 0,
        modifiers:
            item.modifiersGroups.length > 0
              ? parseOrder(item.modifiersGroups)
              : [],
      };
      return newItem;
    }))
    .flat()
    .filter((item) => item.quantity > 0);
}
