<script lang="ts" setup>
const metadataStore = useMetadataStore();
defineProps({
  itemsInCart: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div
    class="absolute top-0 z-10 left-4"
  >
    <div class="relative top-0 flex justify-center w-12 h-12">
      <svg class="absolute" width="45" height="57" viewBox="0 0 45 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.1356 56.7427L22.0678 48.2535L0 56.7427V0H44.1356V56.7427Z" :fill="metadataStore.brandSettings?.colors.primary" />
      </svg>
      <span
        class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 text-[26px] w-7 text-center text-white font-semibold"
      >{{ itemsInCart }}</span>
    </div>
  </div>
</template>
