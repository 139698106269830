/* eslint-disable vue/max-len */
import { RouteRecordName } from 'vue-router';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { HubsterCreateOrderPayload } from '@slabcode/hubster-models/hubster/payloads/manager_orders';
import { FulfillmentMode, PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { KioskCartItem } from '@/modules/orders/interfaces';
import { PaymentInfoDto } from '@/modules/orders/interfaces/job-response';
import { getCartItemDescription } from '../utils';

export function useGTMEventsComposable() {
  const menuStore = useMenuStore();
  const settingsStore = useSettingsStore();
  const brand = computed(() => settingsStore.integration.toString().toUpperCase().replace('-KIOSK', ''));

  /**
   * ECOMMERCE EVENTS
   */
  function triggerViewItemList(category: HubsterCategory) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        item_list_id: category.id,
        item_list_name: category.name,
        currency: menuStore.productsCurrency,
        items: menuStore.products.map((product, index) => ({
          item_id: product.id,
          item_name: product.name,
          index,
          item_list_name: category.name,
          price: product.price.amount,
          quantity: 1,
        })),
      },
    });
  }

  function triggerSelectItem(product: HubsterMenuPublishItem, category: Partial<HubsterCategory>) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        item_list_id: category.id,
        item_list_name: category.name,
        currency: menuStore.productsCurrency,
        items: [{
          item_id: product.id,
          item_name: product.name,
          price: product.price.amount,
          item_list_name: category.name,
          quantity: 1,
        }],
      },
    });
  }

  function triggerAddToCart(
    { section, product, modifiers, totalPrice, category, quantity = 1, identifier, operation }:
    { section: RouteRecordName, product: KioskCartItem, modifiers: HubsterOrderModifier[], totalPrice: number, category: Partial<HubsterCategory>, quantity: number, identifier: string, operation?: string },
  ) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        value: totalPrice,
        currency: menuStore.productsCurrency,
        section,
        operation: operation ?? 'ADD',
        items: [{
          item_id: product.id,
          item_name: product.name,
          price: getCartItemPrice(product),
          item_list_id: category.id,
          item_list_name: category.name,
          item_variant: getCartItemDescription(modifiers),
          identifier,
          quantity,
        }],
      },
    });
  }

  function triggerRemoveFromCart(
    { product, modifiers, totalPrice, category, quantity = 1, identifier }:
    { product: KioskCartItem, modifiers: HubsterOrderModifier[], totalPrice: number, category: Partial<HubsterCategory>, quantity: number, identifier: string },
  ) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        value: totalPrice,
        currency: menuStore.productsCurrency,
        items: [{
          item_id: product.id,
          item_name: product.name,
          price: getCartItemPrice(product),
          item_list_id: category.id,
          item_list_name: category.name,
          item_variant: getCartItemDescription(modifiers),
          identifier,
          quantity,
        }],
      },
    });
  }

  function triggerViewCart(
    { totalPrice, items }:
    { totalPrice: number, items: KioskCartItem[] },
  ) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        currency: menuStore.productsCurrency,
        value: totalPrice,
        items: items.map((product, index) => {
          const category = menuStore.categories.find((c) => c.id === product.categoryId);

          return {
            item_id: product.id,
            item_name: product.name,
            index,
            item_list_id: category?.id,
            item_list_name: category?.name,
            price: getCartItemPrice(product),
            quantity: product.quantity,
            item_variant: getCartItemDescription(product.modifiers),
            identifier: product.identifier,
          };
        }),
      },
    });
  }

  function triggerBeginCheckout(
    { order, items, attempt }:
    { order: HubsterCreateOrderPayload, items: KioskCartItem[], attempt: number },
  ) {
    const { customerPayments, customer, fulfillmentInfo } = order;
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        brand: brand.value,
        table_number: fulfillmentInfo?.tableIdentifier,
        client_name: customer?.name ?? '',
        client_email: customer?.email ?? '',
        attempt,
        disposition: fulfillmentInfo?.fulfillmentMode,
        payment_type: customerPayments![0].paymentMethod,
        currency: menuStore.productsCurrency,
        transaction_id: settingsStore.gtmTransactionId,
        kiosk: settingsStore.storeId,
        items: items!.map((product, index) => {
          const category = menuStore.categories.find((c) => c.id === product.categoryId);

          return {
            item_id: product.id,
            item_name: product.name,
            index,
            item_list_id: category?.id,
            item_list_name: category?.name,
            price: getCartItemPrice(product),
            quantity: product.quantity,
            item_variant: getCartItemDescription(product.modifiers ?? []),
            identifier: product.identifier,
          };
        }),
      },
    });
  }

  function triggerAddPaymentInfo(type: PaymentMethod, details?: PaymentInfoDto) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        brand: brand.value,
        franchise: details?.franchise ?? '',
        entry_mode: details?.entryMode ?? '',
        account_type: details?.accountType ?? '',
        payment_details: details,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        payment_type: type,
      },
    });
  }

  function triggerPurchase(
    { order, items, attempt, id }:
    { order: HubsterCreateOrderPayload; items: KioskCartItem[], attempt: number; id: string },
  ) {
    const { customerPayments, customer, fulfillmentInfo, orderTotal } = order;
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        brand: brand.value,
        time: order.orderedAt,
        order: id, // orderId
        table_number: fulfillmentInfo?.tableIdentifier,
        client_name: customer?.name ?? '',
        client_email: customer?.email ?? '',
        value: orderTotal.total, // total - taxes
        tax: orderTotal.tax,
        attempt,
        disposition: fulfillmentInfo?.fulfillmentMode,
        payment_type: customerPayments![0].paymentMethod,
        currency: menuStore.productsCurrency,
        transaction_id: settingsStore.gtmTransactionId,
        kiosk: settingsStore.storeId,
        items: items!.map((product, index) => {
          const category = menuStore.categories.find((c) => c.id === product.categoryId);

          return {
            item_id: product.id,
            item_name: product.name,
            index,
            item_list_id: category?.id,
            item_list_name: category?.name,
            price: getCartItemPrice(product),
            quantity: product.quantity,
            item_variant: getCartItemDescription(product.modifiers ?? []),
            identifier: product.identifier,
          };
        }),
      },
    });
  }

  function triggerCancelOrder(
    { items, section, isModal }:
    { items: KioskCartItem[]; section: string; isModal: boolean },
  ) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'cancel_order',
      ecommerce: {
        brand: brand.value,
        time: new Date().toLocaleString('sv'),
        section,
        idle_modal: isModal,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        items: items?.map((product, index) => {
          const category = menuStore.categories.find((c) => c.id === product.categoryId);

          return {
            item_id: product.id,
            item_name: product.name,
            index,
            item_list_id: category?.id,
            item_list_name: category?.name,
            price: product.price,
            quantity: product.quantity,
            item_variant: getCartItemDescription(product.modifiers),
            identifier: product.identifier,
          };
        }),
      },
    });
  }

  function triggerScaleUpItem(
    { item, isEdit, value, identifier }:
    { item: HubsterMenuPublishItem, isEdit: boolean, value: number, identifier: string },
  ) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'scale_up_item',
      ecommerce: {
        brand: brand.value,
        process: isEdit ? 'edit' : 'add',
        scale_up: value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        item_id: item.id,
        item_name: item.name,
        identifier,
      },
    });
  }

  /**
   * CUSTOM EVENTS
   */
  function triggerStartOrder() {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'start_order',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        time: new Date().toLocaleString('sv'),
      },
    });
  }

  function triggerSelectOrderType(disposition: FulfillmentMode) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'select_order_type',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        disposition,
      },
    });
  }

  function triggerAddTableNumber(value: string) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'add_table_number',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        disposition: FulfillmentMode.DINE_IN,
        table_number: value,
      },
    });
  }

  function triggerSelectCategory(category: HubsterCategory) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'select_category',
      ecommerce: {
        brand: brand.value,
        category_name: category.name,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
      },
    });
    // Trigger ViewItemList event
    triggerViewItemList(category);
  }

  function triggerIdleModal(section: RouteRecordName) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'idle_modal',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        section,
      },
    });
  }

  function triggerCancelIdleModal(section: RouteRecordName) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'cancel_idle_modal',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        section,
      },
    });
  }

  function triggerGoBack(section: RouteRecordName) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'go_back',
      ecommerce: {
        brand: brand.value,
        kiosk: settingsStore.storeId,
        transaction_id: settingsStore.gtmTransactionId,
        section,
      },
    });
  }

  function triggerEditVariation(
    { history, category, product, identifier, isEdit }:
    { history: any[], identifier: string, isEdit: boolean, category: HubsterCategory, product: any },
  ) {
    history.forEach((item) => {
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'edit_variation',
        ecommerce: {
          brand: brand.value,
          kiosk: settingsStore.storeId,
          transaction_id: settingsStore.gtmTransactionId,
          section: item.section,
          process: isEdit ? 'edit' : 'add',
          modifier_name: item.name,
          modifier_id: item.id,
          level: item.level,
          quantity: item.quantity,
          parent_modifier_name: item.parent_modifier_name,
          parent_modifier_id: item.parent_modifier_id,
          price: item.price,
          identifier,
          item_id: product.id,
          item_name: product.name,
          item_list_id: category.id,
          item_list_name: category.name,
        },
      });
    });
  }

  return {
    triggerViewItemList,
    triggerSelectItem,
    triggerAddToCart,
    triggerRemoveFromCart,
    triggerViewCart,
    triggerBeginCheckout,
    triggerPurchase,
    triggerAddPaymentInfo,
    triggerCancelOrder,
    triggerStartOrder,
    triggerSelectOrderType,
    triggerAddTableNumber,
    triggerSelectCategory,
    triggerIdleModal,
    triggerCancelIdleModal,
    triggerGoBack,
    triggerScaleUpItem,
    triggerEditVariation,
  };
}
