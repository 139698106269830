/* eslint-disable max-lines-per-function */
import { defineStore } from 'pinia';
import { HubsterCreateOrderPayload } from '@slabcode/hubster-models/hubster/payloads/manager_orders';
import { FulfillmentMode, OrderWebhookEventType,
  PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { Customer, KioskCartItem, PartialWebhook } from '@/modules/orders/interfaces';
import requests from '../common/plugins/axios';
import { JobResponseDto,
  JobStatus } from '../modules/orders/interfaces/job-response';
import { DeepPartial } from '../common/interfaces/deep-partial';

type State = {
  jobResponse: JobResponseDto | null;
  cardProcess: boolean;
  retry: number;
  internalError: boolean;
  paymentInProgress: boolean,
  timeout: NodeJS.Timeout | null;
  paymentType: PaymentMethod | null;
  customer: Customer,
  currentOrder: DeepPartial<HubsterCreateOrderPayload> | null;
};

type CreateOrderProps = {
  items: KioskCartItem[];
  orderTotal: Partial<HubsterOrderTotal>;
  paymentMethod: PaymentMethod;
  customer: Customer;
  storeId: string;
  fulfillmentMode: FulfillmentMode,
  tableNumber?: string;
  currencyCode?: string;
};

const INITIAL_CUSTOMER_INFO: Customer = {
  name: '',
  email: '',
  phone: '',
  taxIdentificationNumber: '',
  clientType: 'false',
};

const INITIAL_STATE: State = {
  jobResponse: null,
  cardProcess: false,
  internalError: false,
  paymentInProgress: false,
  retry: 0,
  timeout: null,
  paymentType: null,
  customer: { ...INITIAL_CUSTOMER_INFO },
  currentOrder: null,
};

export const useWebhookStore = defineStore('webhook', {
  state: (): State => ({ ...INITIAL_STATE }),

  getters: {
    orderId: (state) => {
      if (!state.jobResponse) return null;

      return state.jobResponse.orderId || state.jobResponse.posInfo?.orderId;
    },

    /**
     * Check if is a success cash transaction
     * @param state current state
     */
    isSuccessCashTransaction: ({ jobResponse, cardProcess }) => {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.INJECTION_SUCCEEDED && !cardProcess;
    },

    /**
     * Check if is a success card transaction
     * @param state current state
     */
    isSuccessCardTransaction: ({ jobResponse, cardProcess }) => {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.INJECTION_SUCCEEDED && cardProcess;
    },

    /**
     * Check if is a card transaction still in progress
     * @param state current state
     */
    isProcessCardTransaction: (state) => {
      const { jobResponse, cardProcess } = state;
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.PROCESSING && cardProcess;
    },

    /**
     * Check if is the first card failed attempt
     * @param state current state
     */
    isFirstRejected: ({ jobResponse, retry }) => {
      if (!jobResponse) return false;

      const { status } = jobResponse;
      const fail = status === JobStatus.PAYMENT_FAILED;
      const firstError = retry < 2;
      return fail && firstError;
    },

    /**
     * Check if the card payment is already retried and still failing
     * @param state current state
     */
    isPaymentRejected: ({ jobResponse, retry }) => {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      const fail = status === JobStatus.PAYMENT_FAILED;
      const isOtherAttempt = retry > 0;
      return fail && isOtherAttempt;
    },

    /**
     * Injected to hubster successfully but not in PRB
     */
    isSuccessfullyButPOSFails({ jobResponse }) {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.INJECTION_FAILED;
    },
  },

  actions: {
    /**
     * Create the order structure and send the webhook
     *
     * @param {KioskCartItem[]} items the products added to cart
     * @param {number} total the total cost of that products
     */
    async createOrder(orderData: CreateOrderProps, lang: string): Promise<void> {
      const language = lang.toUpperCase();
      this.paymentType = orderData.paymentMethod;
      this.currentOrder = this.buildOrder(orderData);
      const webhook = this.buildWebhook(this.currentOrder, orderData.storeId);

      const { triggerBeginCheckout } = useGTMEventsComposable();
      // Trigger GTM events
      triggerBeginCheckout({
        order: this.currentOrder as HubsterCreateOrderPayload,
        items: orderData.items,
        attempt: this.retry + 1,
      });

      try {
        // Start order
        this.paymentInProgress = true;
        this.jobResponse = null;
        this.cardProcess = false;
        const newOrder = (await requests.post(`callback?language=${language}`, webhook)) as JobResponseDto;
        const res = (await requests.get(`jobs/${newOrder.jobId}`)) as JobResponseDto;
        this.jobResponse = res;
        this.validateStatus(res.status, newOrder.jobId);
      } catch {
        this.internalError = true;
      }
    },

    /**
     * Create a recursive function that is called to verify the new order state
     * @param status current order status
     * @param jobId current order id
     */
    async validateStatus(status: JobStatus, jobId: string) {
      if (
        status === JobStatus.PAYMENT_FAILED
        || status === JobStatus.INJECTION_FAILED
        || status === JobStatus.INJECTION_SUCCEEDED
        || status === JobStatus.INJECTION_IGNORE
        || status === JobStatus.UNKNOWN_ERROR
      ) {
        this.paymentInProgress = false;
        return;
      }

      const res = (await requests.get(`jobs/${jobId}`)) as JobResponseDto;
      this.jobResponse = res;
      this.timeout = setTimeout(() => {
        this.validateStatus(res.status, jobId);
      }, 2000);
    },

    /**
     * Reset job id
     */
    restartWebhook() {
      if (this.timeout) clearTimeout(this.timeout);
      // Reset state
      this.$reset();
      // Reset customer info
      this.$patch({ customer: { ...INITIAL_CUSTOMER_INFO } });
    },

    /**
     * modify retry attempt
     */
    sendRetry() {
      this.retry += 1;
    },

    /**
     * This organize the data to build the order
     * @param {KioskCartItem[]} items
     * @param {number} total
     */
    buildOrder({
      customer,
      items,
      paymentMethod,
      orderTotal,
      tableNumber,
      fulfillmentMode,
      currencyCode,
    }: CreateOrderProps): DeepPartial<HubsterCreateOrderPayload> {
      const metadataStore = useMetadataStore();
      const order: DeepPartial<HubsterCreateOrderPayload> = {
        items,
        currencyCode: currencyCode ?? metadataStore.clusterSettings?.currency.preferredCurrencyCode,
        orderedAt: new Date().toLocaleString('sv'),
        customer: {
          ...customer,
          personalIdentifiers: {
            taxIdentificationNumber: customer.taxIdentificationNumber,
          },
        },
        orderTotal,
        customerPayments: [
          {
            value: orderTotal.total,
            paymentMethod,
          },
        ],
        fulfillmentInfo: {
          tableIdentifier: tableNumber,
          fulfillmentMode,
        },
      };

      return order;
    },

    /**
     * This build the webhook default structure to send the order
     *
     * @param {Partial<HubsterCreateOrderPayload>} order
     */
    buildWebhook(
      order: DeepPartial<HubsterCreateOrderPayload>,
      storeId: string,
    ): PartialWebhook {
      return {
        eventType: OrderWebhookEventType.NewOrder,
        metadata: {
          storeId,
          applicationId: '',
          resourceId: '',
          payload: order,
          resourceHref: '',
        },
      };
    },
  },
});
