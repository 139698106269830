import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { parseOrder } from '../common/utils/parseOrder';
import { CollapsableModifierGroup,
  HubsterMenuModifierWithImage,
  ModifierGroup, ModifierUpdate } from '../modules/orders/interfaces';

type State = {
  isEdit: boolean;
  modifiersGroups: CollapsableModifierGroup[];
  currentOpenModifier: CollapsableModifierGroup | null;
  currentIndexModifier: number;
  currentProduct: HubsterMenuPublishItem | null;
  currentDepth: number,
  history: any[];
};

type UpdateQuantityProps = {
  item: HubsterMenuModifierWithImage;
  modifierGroup: ModifierGroup;
  quantity: number;
};

const INITIAL_STATE: State = {
  isEdit: false,
  modifiersGroups: [],
  currentOpenModifier: null,
  currentIndexModifier: 0,
  currentProduct: null,
  currentDepth: 0,
  history: [],
};

export const useModifierStore = defineStore('modifier', {
  state: (): State => ({ ...INITIAL_STATE }),

  getters: {
    modifiers: (state) => {
      if (!state.modifiersGroups.length) return [];
      const menuStore = useMenuStore();
      return state.modifiersGroups.map((modifier) => {
        const { items, minimumSelections, maximumSelections } = modifier;
        const selectedItems = items.filter((item) => item.quantity > 0);

        const modifierSelections = selectedItems.reduce((acc, item) => acc + item.quantity, 0);
        // Check modifier selctions
        const isModifierValid = (modifierSelections >= minimumSelections) && (modifierSelections <= maximumSelections);
        // Check deep items
        const areSelectedItemsValid = selectedItems.every((item) => {
          const deepModifiers = item.modifiersGroups;

          if (!deepModifiers) return true;
          // Check if every subitem has minimun expected selections
          return deepModifiers.every((childMod) => {
            const childSelections = childMod.items.reduce((prev, acc) => prev + (acc.quantity), 0);

            return childSelections >= (childMod.minimumSelections * item.quantity)
              && childSelections <= (childMod.maximumSelections * item.quantity);
          });
        });

        return {
          ...modifier,
          items: items.filter((i) => {
            if (menuStore.alcohol.allow) return i;
            return !i.skuDetails.containsAlcohol;
          }),
          valid: isModifierValid && areSelectedItemsValid,
        };
      });
    },

    selectedItems(): HubsterOrderModifier[] {
      if (!this.modifiersGroups.length) return [];

      return parseOrder(this.modifiers);
    },

    /**
     * Return if is an upgradeable modifier
     * @param state current state
     */
    hasUpgrade: (state) => {
      if (!state.modifiersGroups.length) return null;

      return state.modifiersGroups.find(
        (modifier) => modifier.name.includes('Upgrade'),
      ) ?? null;
    },

    currentSelectedItems: (state) => {
      if (!state.currentOpenModifier) return 0;

      return state.currentOpenModifier.items.reduce((prev, acc) => prev + acc.quantity, 0);
    },

    // TODO: Use in Customized view components
    areSelectedModifiersValid(): boolean {
      if (!this.currentOpenModifier) return false;
      const { itemIds, items, minimumSelections } = this.currentOpenModifier;

      const hasExpectedSelection = this.selectedItems
        .filter((i) => itemIds.includes(i.id!))
        .reduce((acc, curr) => acc + curr.quantity, 0);

      const hasExpectedModifiersSelection = items
        .filter((m) => this.selectedItems.some((c) => c.id === m.id))
        // eslint-disable-next-line vue/max-len
        .every((i) => i.modifiersGroups.every((m) => m.items.reduce((acc, curr) => acc + curr.quantity, 0) >= m.minimumSelections));

      return (hasExpectedSelection >= minimumSelections) && hasExpectedModifiersSelection;
    },

    priceWithModifiers(): number {
      if (!this.currentProduct) return 0;
      const productPrice = this.currentProduct.price.amount;

      const getModPrice = (children: HubsterOrderModifier[], price: number) =>
        children.reduce((acc, curr) => {
          let childrenPrice = 0;
          if (curr.modifiers && curr.modifiers.length > 0) childrenPrice = getModPrice(curr.modifiers!, 0);

          return acc + ((curr.price! + childrenPrice) * curr.quantity);
        }, price);

      return getModPrice(this.selectedItems, productPrice);
    },
  },

  actions: {
    setCurrentProduct(item: HubsterMenuPublishItem) {
      this.currentProduct = item;
    },

    /**
     * Loads the modifier groups into the store.
     *
     * @param modifierGroup - An array of collapsible modifier groups.
     */
    loadModifierGroup(modifierGroup: CollapsableModifierGroup[]) {
      this.setModifiers(modifierGroup);
    },

    /**
     * Update the modifiers
     * @param {ModifierGroup[]} modifiers new modifiers
     */
    setModifiers(modifiers: ModifierGroup[]) {
      this.modifiersGroups = modifiers.map((modifier, index) => {
        const collapseModifier = {
          ...modifier,
          collapse: index !== 0,
        };

        if (index === 0) {
          this.currentOpenModifier = collapseModifier;
          this.currentIndexModifier = index;
        }

        return collapseModifier;
      });
    },

    /**
     * This update the quantity of the each modifier in a modifier group
     *
     * @param {UpdateQuantityProps} {
     *       item, -> this represents the item that will be update
     *       modifierGroup, -> this represents the group where the modifier is
     *       quantity, -> this represent the quantity that was add to the modifier
     *       unique = false, -> this is used to clear the other quantity's modifier if it is unique.
     *  Ex. Radio button modifier just can be one value
     *     }
     */
    updateItemQuantity({ item, modifierGroup, quantity }: UpdateQuantityProps) {
      const currentModifierGroup = this.modifiersGroups.find(
        (group) => group.id === modifierGroup.id,
      );

      if (!currentModifierGroup) return;
      if (currentModifierGroup.maximumSelections === 1) {
        currentModifierGroup.items = currentModifierGroup.items.map((it) => ({
          ...it,
          groupId: modifierGroup.id,
          quantity: 0,
        }));
      }

      const currentItem = currentModifierGroup.items.find(
        (it) => it.id === item.id,
      );

      if (!currentItem || quantity > currentModifierGroup.maximumSelections) {
        return;
      }

      this.changeModifierQuantity(currentModifierGroup, currentItem, quantity);
    },

    /**
     * This function decrease automatically the first modifier item if the group is full
     * @param group current modifier group
     * @param currentItem current modifier item that was edit
     */
    // eslint-disable-next-line max-lines-per-function
    changeModifierQuantity(
      group: ModifierGroup,
      currentItem: HubsterMenuModifierWithImage,
      quantity: number,
    ) {
      const selectedModifiers = this.selectedModifiersGroups(group);
      const firstItem = group.items.at(0);
      const current = group.items.find((item) => item.id === currentItem.id);

      if (!firstItem || !current) return;

      current.groupId = group.id;
      const currentItemDecrease = quantity < current.quantity;
      // There is not full modifier or the first item is decreasing
      if (
        selectedModifiers < group.maximumSelections
        || (currentItemDecrease && firstItem.id === current.id)
      ) {
        current.quantity = quantity;
        return;
      }

      // First item is empty and item is increasing
      if (firstItem.quantity === 0 && !currentItemDecrease) return;
      current.quantity = quantity;
      if (currentItemDecrease) {
        firstItem.quantity += 1;
        return;
      }

      firstItem.quantity -= 1;
    },

    /**
     * calculate the already selected modifiers
     * @param group to calculate the total quantities
     */
    selectedModifiersGroups(group: ModifierGroup) {
      return group.items.reduce((qty, it) => qty + it.quantity, 0);
    },

    /**
     * Find the modifier to upgrade and change its quantity to take into account
     * when the item is save
     */
    addUpgradeModifier() {
      const upgradeModifier = this.modifiersGroups.find((modifier) =>
        modifier.name.includes('Upgrade'));

      if (!upgradeModifier) {
        // eslint-disable-next-line no-console
        console.error(
          'Always that this function is called should be have this modifier',
        );
        return;
      }

      const modifierItem = upgradeModifier.items.at(0);
      modifierItem!.quantity += 1;
    },

    /**
     * this function remove all selected modifiers in the group
     * @param modifier modifier to reset
     */
    resetModifierSelection(modifier: CollapsableModifierGroup) {
      const currentModifier = this.modifiersGroups.find(
        (m) => m.id === modifier.id,
      );
      if (!currentModifier) return;
      currentModifier.items = currentModifier.items.map((item) => ({
        ...item,
        quantity: 0,
      }));
    },

    /**
     * Updates 'currentIndexModifier' value and updates
     * 'currentOpenModifier' with new index.
     * @param {number} index - Modifiers index
     */
    changeCurrentModifier(index: number): void {
      const newModifier = this.modifiersGroups[index];
      if (!newModifier || !this.currentOpenModifier) return;
      // Update collapse status
      this.currentOpenModifier.collapse = true;
      this.modifiersGroups[index].collapse = false;
      // Update new index
      this.currentIndexModifier = index;
      this.currentOpenModifier = newModifier;
    },

    /**
     * This function receipt a tuple array to find the deepest modifier
     * Ex. [['groupId', 'childId'], ['evenDeepGroupId', 'deepChild']]
     * and modify the last deep child that receipt in that route
     * @param modifierRoute Tuple that defines the [groupId, itemId] is recursive searching
     * @param quantity the quantity to update in the item
     */
    updateRecursivelyModifier(
      modifierRoute: [string, string][],
      quantity: number,
    ) {
      let groups: CollapsableModifierGroup[] | ModifierGroup[] = this.modifiersGroups;
      let child: HubsterMenuModifierWithImage | null = null;

      modifierRoute.forEach(([groupId, itemId]) => {
        child = this.getChild(groups, groupId, itemId);
        groups = child?.modifiersGroups ?? [];
      });

      if (!child) return;
      const currentChild = child as HubsterMenuModifierWithImage;
      currentChild.quantity = quantity;
    },

    /**
     * this function receipt a groups array and the current group id and child id to return it
     * @param groups the group to search
     * @param groupId the current group id
     * @param itemId the current item id
     */
    getChild(
      groups: CollapsableModifierGroup[] | ModifierGroup[],
      groupId: string,
      itemId: string,
    ) {
      const modifier = groups.find((group) => group.id === groupId);
      if (!modifier) return null;
      const currentItem = modifier.items.find((item) => item.id === itemId);
      if (!currentItem) return null;
      return currentItem;
    },

    // TODO: Make refactor
    updateCamilo(updateInfo: ModifierUpdate) {
      const { item, quantity, type } = updateInfo.data;
      const selectedModifier = this.modifiersGroups.find((m) => m.id === updateInfo.modifierId);

      if (!selectedModifier) return;

      const index = selectedModifier.items.findIndex((i) => i.id === item.id);
      const currentQuantity = selectedModifier.items[index].quantity;
      const selectedModifiers = selectedModifier.items.reduce((prev, acc) => prev + acc.quantity, 0);

      if ((type !== 'checkbox') && (quantity > currentQuantity)) {
        const { modifiersGroups } = selectedModifier.items[index];
        const hasMaxModifiers = (selectedModifiers === selectedModifier.maximumSelections);
        const hasMultipleModifiers = modifiersGroups.length > 1;
        const defaultModifierIndex = modifiersGroups.findIndex((m) => m.items.length === 1);
        let firstIndex = selectedModifier.items.findIndex((m) => m.quantity > 0);

        if (firstIndex !== -1) {
          if (selectedModifier.items[firstIndex].id === item.id) {
            firstIndex = selectedModifier.items.findIndex(
              (m) => (m.quantity > 0) && m.id !== item.id,
            );
          }

          if (hasMaxModifiers && (selectedModifier.items[firstIndex].quantity > 0)) {
            selectedModifier.items[firstIndex].quantity -= 1;
            this.history.push({ ...selectedModifier.items[firstIndex] });
          }
        }

        if (defaultModifierIndex > -1 && hasMultipleModifiers) {
          // TODO: Reset items if (currentQuantity < quantity)
          const defaultMod = modifiersGroups[defaultModifierIndex];
          defaultMod.items[0].quantity = quantity * defaultMod.minimumSelections;
        }
      }

      // Make update
      selectedModifier.items[index].quantity = quantity;
      this.history.push({ ...selectedModifier.items[index] });
    },

    $reset() {
      this.$patch({ ...INITIAL_STATE });
    },
  },
});

export default useModifierStore;
