<script setup lang="ts">
import { formatCurrency, getCartItemDescription } from '@/common/utils';
import { KioskCartItem } from '@/modules/orders/interfaces';

const props = defineProps({
  product: {
    type: Object as PropType<KioskCartItem>,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  priceDecimals: {
    type: Number,
    required: true,
  },
});

// TODO: Add increase/decrease events to migrate logic to parent component
const emits = defineEmits({
  delete: (totalPrice: number) => ({ totalPrice }),
  update: (item: KioskCartItem) => ({ item }),
});

const route = useRoute();
const cartStore = useCartStore();
const menuStore = useMenuStore();
const metadataStore = useMetadataStore();
const { voucherProducts } = storeToRefs(menuStore);
const { counterStyle } = storeToRefs(metadataStore);
const { triggerAddToCart, triggerRemoveFromCart } = useGTMEventsComposable();

const productTotal = computed(() => getCartItemPrice(props.product));

const haveModifiers = computed(() => {
  const item = menuStore.getItemById(props.product.id!);
  if (!item) return false;

  return item.modifierGroupIds.length > 0;
});

const isVoucherProduct = computed(() => voucherProducts.value.some(
  (p) => p.id === props.product.id,
));

/**
 * refresh the state reference for that specific product to calc again the totals
 * @param {number} quantity have the current item quantity
 */
function updateItems(quantity: number) {
  if (quantity === 0) {
    emits('delete', productTotal.value);
    return;
  }

  // const product = menuStore.getItemById(props.product.id);
  const productCategory = menuStore.categories.find((c) => c.id === props.product.categoryId);

  if (productCategory) {
    const gtmEvent = (quantity > props.product.quantity) ? triggerAddToCart : triggerRemoveFromCart;
    // Trigger GTM events
    gtmEvent({
      section: route.name,
      product: props.product,
      modifiers: props.product.modifiers,
      totalPrice: productTotal.value / props.product.quantity,
      category: productCategory,
      quantity: 1,
      identifier: props.product.identifier ?? '',
    });
  }
  // Update item quantity
  cartStore.updateItemQuantity(props.index, quantity);
}
</script>

<template>
  <div class="py-1 shopping-cart-container">
    <div class="pt-3 shopping-cart-header">
      <div class="flex w-2/3 gap-5">
        <img
          :src="product.note"
          :alt="`${product.name} image`"
          class="object-cover object-center mb-1 w-52 h-52"
        />

        <div class="flex flex-col justify-center mx-8">
          <h2 class="mb-2 text-3xl font-bold leading-8 uppercase">
            {{ product.name }}
          </h2>

          <p class="text-[26px]">
            {{ getCartItemDescription(product.modifiers) }}
          </p>
        </div>
      </div>

      <span class="price-container">{{
        formatCurrency(currency, productTotal, priceDecimals)
      }}</span>
    </div>

    <div class="flex justify-between">
      <div class="flex items-center gap-4 px-4">
        <button
          type="button"
          class="text-3xl font-bold uppercase disabled:text-neutral-300"
          :disabled="!haveModifiers || isVoucherProduct"
          @click="$emit('update', product)"
        >
          {{ $t('EDIT') }}
          <span class="ml-2 icon icon-edit" />
        </button>

        <div class="h-8 w-[2px] bg-neutral-200" />

        <button
          type="button"
          class="flex items-center justify-center text-3xl font-bold uppercase"
          @click="$emit('delete', productTotal)"
        >
          {{ $t('DELETE') }}
          <span class="ml-2 icon icon-trash" />
        </button>
      </div>

      <KioskCounter
        :disabled="isVoucherProduct"
        :model-value="product.quantity"
        :button-style="counterStyle"
        @update:model-value="updateItems($event)"
      />
    </div>
  </div>
</template>

<style scoped>
.price-container {
  @apply flex items-center justify-center font-bold text-4xl mr-10;
}

.icon-button {
  @apply text-2xl font-bold uppercase flex items-center;
}

.shopping-cart-container {
  @apply flex flex-col px-5 border-b border-neutral-200;
}

.shopping-cart-header {
  @apply flex gap-5 justify-between;
}

</style>
