<script setup lang="ts">
import { useMousePressed } from '@vueuse/core';

const page = ref<HTMLElement>();
const inactiveStore = useInactiveStore();
const target = computed(() => (inactiveStore.currentKey === 'timer' ? page : null));
const { pressed } = useMousePressed({ target: target.value, touch: false });

watch(pressed, (newValue) => {
  if (newValue && target.value) inactiveStore.resetCountdown('timer');
});
</script>

<template>
  <section ref="page" class="full-hd">
    <div id="modals" />
    <router-view />
  </section>
</template>
