import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { orderRoutes } from '@/modules/orders/routes.ts';
// Loading views without lazy
import Init from '@/Init.vue';
import NotFound from '@/NotFound.vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/:integration/:storeId',
    component: Init,
    name: 'Store',
    children: [
      {
        path: '',
        name: 'WelcomeView',
        component: () => import('@/modules/welcome/WelcomeView.vue'),
      },
      {
        path: 'standby',
        name: 'StandbyBanner',
        component: () => import('@/modules/welcome/views/StandbyBannerView.vue'),
      },
      {
        path: 'order',
        name: 'Orders',
        children: [...orderRoutes],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Check if exist metadata loaded in store to allow routing, otherwise ... Back to welcome view
// router.beforeEach((to) => {
//   const { integration, storeId } = to.params;

//   if (!integration || !storeId) {
//     return { name: 'not-found', params: undefined };
//   }

//   const metadata = useMetadataStore();

//   if ('integration' in to.params && 'storeId' in to.params) {
//     metadata.$patch({
//       integration: integration.toString(),
//       storeId: storeId.toString(),
//     });
//   }

//   if ((to.name !== 'WelcomeView') && (!metadata.kioskSettings)) {
//     return { name: 'WelcomeView', params: to.params };
//   }

//   return true;
// });

export default router;
