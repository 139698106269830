<!-- eslint-disable vue/max-len -->
<script setup lang="ts">
import { PropType } from 'vue';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { CollapsableModifierGroup, ModifierChange } from '../interfaces';

const props = defineProps({
  modifiers: {
    type: Array as PropType<CollapsableModifierGroup[]>,
    required: true,
  },
  modifierIndex: {
    type: Number,
    required: true,
  },
  selectedItems: {
    type: Array as PropType<HubsterOrderModifier[]>,
    required: true,
  },
  isCurrentValid: {
    type: Boolean,
    required: true,
  },
  isEdit: {
    type: Boolean,
    required: true,
  },
  productPrice: {
    type: Number,
    required: false,
    default: 0,
  },
  priceDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
  counterStyle: {
    type: String,
    required: false,
    default: 'fill',
  },
  showFullAnimation: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  counterChanged: [{ data: ModifierChange, modifierId: string }],
  goToStep: [index: number],
  updateCollapse: [{ index: number, value: boolean }],
  save: [],
  cancel: [],
}>();

const { t } = useI18n();
const { isEdit, modifierIndex, modifiers, priceDecimals, productPrice, selectedItems } = toRefs(props);

const submitValue = computed(() => {
  const result = isEdit.value ? t('SAVE') : t('ADD');
  const price = formatCurrency('USD', productPrice.value, priceDecimals.value);
  return `${result} | ${price}`;
});

/**
 * Check selected modifiers from specific modifiers
 * group index.
 * @param index - Modifier index
 */
function getSelectedItemsByModifier(index: number) {
  const { items } = modifiers.value[index];

  return items.filter((i) => i.quantity > 0)
    .reduce((acc, curr) => acc + curr.quantity, 0);
}

/**
 * Check current modifierIndex value and emit 'goToStep'
 * if it's the last valid index, otherwise close current collapse.
 * @param {number} index - Modifier index
 */
function onNextClick(index: number) {
  if (index === modifierIndex.value) {
    emit('goToStep', index + 1);
  } else {
    // Close collapse
    emit('updateCollapse', { index, value: true });
  }
}
</script>

<template>
  <div class="mt-0 customization-list">
    <CollapsableContainer
      v-for="(modifier, index) in modifiers"
      :key="modifier.id"
      :id="`item-${index}`"
      :disabled="modifierIndex < index"
      :position="index"
      :title="modifier.name"
      :model-value="modifier.collapse"
      @update:model-value="emit('updateCollapse', { index, value: $event })"
    >
      <template #breadcrumbs>
        <OrderModifierCounter
          :show-full-animation="showFullAnimation && (modifierIndex === index)"
          :min-selections="modifier.minimumSelections"
          :current-selections="getSelectedItemsByModifier(index)"
          :max-selections="modifier.maximumSelections"
        />
      </template>

      <div class="modifier-container">
        <OrderModifiers
          :modifier="modifier"
          :selected-items="selectedItems"
          :price-decimals="priceDecimals"
          :counter-style="counterStyle"
          :depth="0"
          @counter-changed="emit('counterChanged', $event)"
        />
      </div>

      <div
        v-if="(index + 1) < modifiers.length"
        class="flex items-center justify-end w-full gap-5 px-12"
      >
        <KioskButton
          v-if="modifier.minimumSelections === 0"
          text-size="small"
          @click="emit('goToStep', modifierIndex + 1)"
        >
          {{ $t("SKIP") }}
        </KioskButton>

        <KioskButton
          text-size="small"
          color="primary"
          class="animate__animated animate__headShake shake"
          :disabled="!modifier.valid"
          @click="onNextClick(index)"
        >
          {{ $t("NEXT") }}
        </KioskButton>
      </div>
    </CollapsableContainer>
  </div>

  <OrderBottomActions
    :disable-next="!isCurrentValid"
    :back-label="$t('BACK')"
    :next-label="submitValue"
    @back-pressed="emit('cancel')"
    @next-pressed="emit('save')"
  />
</template>

<style scoped>
.modifier-container {
  @apply py-2 px-12 grid grid-cols-3 gap-8 place-content-start justify-items-center;
}

.customization-button {
  @apply flex gap-1 items-center justify-center w-[308px] !h-16 py-3 text-3xl;
}

.shake {
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
