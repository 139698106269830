/* eslint-disable no-unused-vars */
export enum StepName {
  WELCOME = 'WelcomeView',
  ORDER_OPTIONS = 'OrderOptions',
  ORDER_TYPE = 'OrderTypeView',
  ORDER_TABLE = 'OrderTableView',
  ORDER_CATEGORIES = 'OrderCategoriesView',
  PRODUCTS = 'OrderProductsView',
  PRODUCT_CUSTOMIZATION = 'OrderCustomizationView',
  ORDER_CART = 'OrderCartView',
  ORDER_CHECKOUT = 'OrderCheckoutView',
}
