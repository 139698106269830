import { Languages } from '@slabcode/kiosks-core/enums';
import { LegalCheck } from '../interfaces/legalSection';

export const useLegalPolicies = defineStore('legalPolicies', () => {
  // Dependencies
  const metadataStore = useMetadataStore();
  const languageStore = useLanguageStore();
  const webhookStore = useWebhookStore();

  const { customer } = storeToRefs(webhookStore);
  const { legal, customImages, clusterSettings } = storeToRefs(metadataStore);
  const { currentLanguage } = storeToRefs(languageStore);

  const legalChecks = ref<LegalCheck>({
    termsAndConditions: false,
    privacyPolicies: false,
    advertising: false,
  });

  const disableAdvertisingCheckbox = computed(() => {
    if (!customer.value) return false;
    const { email, phone } = customer.value;
    const disable = email.length === 0 && phone.length === 0;
    if (disable) legalChecks.value.advertising = false;
    return disable;
  });

  const legalData = computed(() => {
    const legalValue = legal.value;
    const images = customImages.value;
    if (!legalValue || !images) return null;

    const { advertising, privacyPolicies, termsAndConditions } = legalValue;
    const { privacyPolicies: privacyPoliciesImg, termsAndConditions: termsAndConditionsImg } = images;
    const language = currentLanguage.value.toUpperCase() as Languages;

    return {
      termsAndConditions: {
        image: termsAndConditionsImg,
        display: termsAndConditions.display,
        description: termsAndConditions.messages![language],
      },

      privacyPolicies: {
        image: privacyPoliciesImg,
        display: privacyPolicies.display,
        description: privacyPolicies.messages![language],
      },

      advertising: {
        description: advertising.messages![language],
        display: advertising.display,
      },
    };
  });

  const displayAdvertisingCheckbox = computed(() => {
    if (clusterSettings.value?.basics.customerInfo.email || clusterSettings.value?.basics.customerInfo.phone) {
      return legalData.value?.advertising.display;
    }

    return false;
  });

  const canContinue = computed(() => {
    // If there are no legal data, we can always continue
    if (!legal.value) return true;

    const checks = legalChecks.value;
    const {
      privacyPolicies: { display: displayPrivacyPolicies },
      termsAndConditions: { display: displayTermsAndConditions },
    } = legal.value;

    // Case 1: Both terms and policies are displayed and both must be accepted
    if (displayPrivacyPolicies && displayTermsAndConditions) {
      return checks.termsAndConditions && checks.privacyPolicies;
    }

    // Case 2: Only the terms are displayed, they must be accepted
    if (displayTermsAndConditions && !displayPrivacyPolicies) {
      return checks.termsAndConditions;
    }

    // Case 3: Only the policies are displayed, they must be accepted
    if (!displayTermsAndConditions && displayPrivacyPolicies) {
      return checks.privacyPolicies;
    }

    // If no condition applies, return true
    return true;
  });

  return {
    legalData,
    legalChecks,
    displayAdvertisingCheckbox,
    disableAdvertisingCheckbox,
    canContinue,
  };
});
