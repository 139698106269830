<script setup lang="ts">
import { RouteMap, RouteRecordName } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const settingsStore = useSettingsStore();
const metadataStore = useMetadataStore();
const orderTypeStore = useOrderTypeStore();
const { triggerGoBack } = useGTMEventsComposable();
const { mainFooterImage, mainHeaderImage } = storeToRefs(metadataStore);

const isNotCategories = computed(() => route.name !== 'OrderCategoriesView');

const backRoute = computed(() => {
  if (!route.name) return 'WelcomeView';

  const result: Record<keyof RouteMap, RouteRecordName> = {
    OrderTypeView: 'WelcomeView',
    OrderTableView: 'OrderTypeView',
    OrderCategoriesView: orderTypeStore.isPickupOrder || metadataStore.hideTableNumber ? 'OrderTypeView' : 'OrderTableView',
  };

  return result[route.name];
});

function goBack() {
  // Trigger GTM event
  triggerGoBack(route.name);

  if (route.name !== 'OrderTypeView') {
    router.push({ name: backRoute.value });
    return;
  }

  if (settingsStore.multibrandData) {
    const { backUrl } = settingsStore.multibrandData;
    window.location.href = backUrl;
    return;
  }

  router.push({ name: backRoute.value });
}
</script>

<template>
  <section class="component-wrapper full-hd">
    <img
      v-if="mainHeaderImage"
      :src="mainHeaderImage"
      alt="Main Header"
      class="top-0 custom-image"
    >

    <OrderOptions
      :hide-carousel="!isNotCategories"
      :back-button-text="t('BACK')"
      @go-back="goBack()"
    >
      <router-view />
    </OrderOptions>

    <img
      v-if="mainFooterImage"
      :src="mainFooterImage"
      alt="Main Footer"
      class="bottom-0 custom-image"
    >
  </section>
</template>

<style scoped>
.component-wrapper {
  @apply flex flex-col justify-center items-center relative;
}

.custom-image {
  @apply h-40 absolute left-1/2 -translate-x-1/2;
}
</style>
