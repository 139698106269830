// eslint-disable-next-line no-shadow, no-unused-vars
export enum JobStatus {
  // eslint-disable-next-line no-unused-vars
  PROCESSING = 1,
  // eslint-disable-next-line no-unused-vars
  PAYMENT_SUCCEEDED = 2,
  // eslint-disable-next-line no-unused-vars
  PAYMENT_FAILED = 3,
  // eslint-disable-next-line no-unused-vars
  PAYMENT_IGNORE = 4,
  // eslint-disable-next-line no-unused-vars
  INJECTION_SUCCEEDED = 5,
  // eslint-disable-next-line no-unused-vars
  INJECTION_FAILED = 6,
  // eslint-disable-next-line no-unused-vars
  INJECTION_IGNORE = 7,
  // eslint-disable-next-line no-unused-vars
  UNKNOWN_ERROR = 10,
}

export interface TicketInfoDTO {
  cardNumber: string | null;
  type: string | null;
  inputMode: string | null;
  transactionId: string | null;
  authNumber: string | null;
  amount: string | null;
  signature: string | null;
  timestamp: string | null;
}

export interface SuccessJobResponseDto {
  items: string;
  orderId: number;
  header: string | null;
  footer: string |null;
}

export interface PaymentInfoDto {
  franchise: string, // VISA, MASTERCARD, UNKNOWN
  accountType: string, // CREDITO, DEBITO, UNKOWN
  entryMode: string, // CHIP, CLESS, UNKNOWN
  // franchise: FranchiseEnum, // VISA, MASTERCARD, UNKNOWN
  // accountType: AccountTypeEnum, // CREDITO, DEBITO, UNKOWN
  // entryMode: EntryModeEnum, //CHIP, CLESS, UNKNOWN
}

export interface JobResponseDto {
  jobId: string;
  status: JobStatus;
  posInfo?: SuccessJobResponseDto;
  ticketInfo?: TicketInfoDTO;
  paymentInfo?: PaymentInfoDto;
  message: string;
  ticket?: string;
  orderId?: string;
}
