<script lang="ts" setup>
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { formatCurrency } from '@/common/utils';
import { KioskCartItem } from '../interfaces';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const menuStore = useMenuStore();
const cartStore = useCartStore();
const metadataStore = useMetadataStore();
const { items } = storeToRefs(cartStore);
const { priceDecimals } = storeToRefs(metadataStore);
const { productsCurrency, upSellingBanners } = storeToRefs(menuStore);
const { triggerSelectItem, triggerAddToCart, triggerGoBack, triggerRemoveFromCart, triggerViewCart } = useGTMEventsComposable();

function updateItem(item: KioskCartItem, index: number) {
  cartStore.addItemToUpdate(item, index);

  router.push({
    name: 'OrderCustomizationView',
    params: { itemId: item.id, categoryId: item.categoryId },
  });
}

function confirmedDelete(index: number, product: KioskCartItem, totalPrice: number) {
  const productCategory = menuStore.categories.find((c) => c.id === product.categoryId);

  cartStore.deleteItem(index);

  if (productCategory) {
    triggerRemoveFromCart({
      product,
      modifiers: product.modifiers,
      totalPrice,
      category: productCategory,
      quantity: product.quantity,
      identifier: product.identifier!,
    });
  }
}

function randomNumber(max: number) {
  return Math.floor(Math.random() * max);
}

const randomUpSellingBanner = computed(
  () => {
    if (!upSellingBanners.value.length) return null;
    const { length } = upSellingBanners.value;
    // Show random banner
    return upSellingBanners.value[randomNumber(length)];
  },
);

function getDefaultModifiers(productId: string) {
  const modifiers = menuStore.getModifiersByItem(productId);
  return modifiers.map((modifier) => {
    if (modifier.minimumSelections === 0) {
      return null;
    }

    const [firstItem] = modifier.items;
    const orderItem: HubsterOrderModifier = {
      quantity: modifier.maximumSelections,
      groupId: modifier.id,
      groupName: modifier.name,
      id: firstItem.id,
      name: firstItem.name,
      modifiers: [],
      price: firstItem.price,
    };

    return orderItem;
  }).filter((item) => Boolean(item)) as HubsterOrderModifier[];
}

function addBannerSuggestion() {
  if (randomUpSellingBanner.value) {
    const bannerProduct = randomUpSellingBanner.value;
    const productCategory = menuStore.categories.find((c) => c.id === bannerProduct.id);

    const newProduct: KioskCartItem = {
      ...bannerProduct,
      quantity: 1,
      modifiers: getDefaultModifiers(bannerProduct.id),
      price: bannerProduct.price.amount,
      note: bannerProduct.photoIds[0],
      localId: '',
      categoryId: productCategory?.id ?? '',
      categoryName: productCategory?.name ?? '',
    };

    cartStore.addCartItem(newProduct, true);
    // Trigger GTM events
    triggerSelectItem(bannerProduct, { name: 'UPSELLING' });
    triggerAddToCart({
      product: newProduct,
      modifiers: [],
      totalPrice: newProduct.price,
      category: { name: 'UPSELLING' },
      quantity: 1,
      section: route.name,
      identifier: cartStore.items.at(-1)?.identifier ?? '',
    });
  }
}

function goBack() {
  router.push({ name: 'OrderProductsView' });
  // Trigger GTM event
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  triggerViewCart({
    totalPrice: cartStore.getTotal(),
    items: items.value,
  });
});
</script>

<template>
  <section class="flex flex-col justify-between w-full h-[1690px]">
    <!-- Order total -->
    <div class="cart-amount bg-primary-900">
      <h5 class="flex items-center justify-center amount-info">
        {{ t("TOTAL") }}:
        <strong class="ml-5">{{ formatCurrency(productsCurrency, cartStore.getTotal(), priceDecimals) }}
        </strong>
      </h5>
    </div>

    <section class="h-full overflow-auto animate__animated animate__fadeInLeft">
      <button
        v-if="randomUpSellingBanner"
        class="w-full"
        type="button"
        @click="addBannerSuggestion()"
      >
        <img
          class="object-cover w-full rounded"
          :src="randomUpSellingBanner.photoIds[1]"
          :alt="randomUpSellingBanner.name"
        />
      </button>

      <TransitionGroup name="add-animation">
        <ShoppingCartItem
          v-for="(product, index) in items"
          :key="product.id"
          :product="product"
          :index="index"
          :currency="productsCurrency"
          :price-decimals="priceDecimals"
          @delete="confirmedDelete(index, product, $event)"
          @update="updateItem($event, index)"
        />
      </TransitionGroup>
    </section>

    <OrderBottomActions
      :disable-next="cartStore.items.length === 0"
      :back-label="$t('BACK')"
      :next-label="$t('TIMELINE.PAY')"
      @back-pressed="goBack()"
      @next-pressed="router.push({ name: 'OrderCheckoutView' })"
    />
  </section>
</template>

<style scoped>
.cart-amount {
  @apply relative p-3 h-[92px] w-full text-3xl flex items-center justify-center text-white;
}

.back-button {
  @apply absolute left-5 top-1/2 -translate-y-1/2 w-10 h-10;
}

.text-separator {
  @apply border-r h-10 border-white mx-8 bg-white;
}

.add-animation-enter-active {
  animation: add-slowly 0.5s both;
}
.add-animation-leave-active {
  animation: add-slowly 0.5s both reverse;
}

@keyframes add-slowly {
  from {
    opacity: 0;
    transform: translateY(-70%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
