<script setup lang="ts">
defineProps({
  backLabel: {
    type: String,
    required: true,
  },
  backIcon: {
    type: String,
    required: false,
    default: 'redo',
  },
  disableBack: {
    type: Boolean,
    required: false,
    default: false,
  },
  nextLabel: {
    type: String,
    required: false,
    default: '',
  },
  disableNext: {
    type: Boolean,
    required: false,
    default: false,
  },
  showNextButton: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['backPressed', 'nextPressed', 'catalogPressed']);
</script>

<template>
  <div class="bottom-actions">
    <slot name="prepend" />

    <KioskButton
      :icon="backIcon"
      :disabled="disableBack"
      class="text-black"
      :class="{ '!w-auto': !showNextButton }"
      @click="emit('backPressed')"
    >
      {{ backLabel }}
    </KioskButton>

    <KioskButton
      v-if="showNextButton"
      :disabled="disableNext"
      color="primary"
      @click="emit('nextPressed')"
    >
      {{ nextLabel }}
    </KioskButton>
  </div>
</template>

<style scoped>
.bottom-actions {
  @apply h-28 bg-white flex justify-center items-center gap-10 w-full shadow-2xl shadow-neutral-700 z-10 fixed bottom-0;
}

.bottom-actions button {
  @apply w-1/3 h-[90px] py-3;
}
</style>
