<script lang="ts" setup>
import { PropType } from 'vue';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';

const cartStore = useCartStore();

const props = defineProps({
  product: {
    type: Object as PropType<HubsterMenuPublishItem>,
    required: true,
  },
  allowAlcohol: {
    type: Boolean,
    required: true,
  },
  simple: {
    type: Boolean,
    required: false,
    default: false,
  },
  priceDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
});

const emit = defineEmits(['addItem', 'showAlcoholConfirm']);

const { t } = useI18n();
const { allowAlcohol, product } = toRefs(props);

const containsAlcohol = computed(() => props.product.skuDetails.containsAlcohol);

const currencyPrice = computed(() => formatCurrency(
  'USD',
  props.product.price.amount,
  props.priceDecimals,
));

const itemsInCart = computed(() => {
  const quantity = cartStore.items
    .filter((item) => item.id === props.product.id).reduce((acc, curr) => acc + curr.quantity, 0);

  return addLeftZero(quantity);
});

const productImage = computed(() => props.product.photoIds[0] ?? props.product.description);

const alreadyAddedItem = computed(() => Number(itemsInCart.value) > 0);

const canBeAdded = computed(() => {
  const { status, skuDetails } = product.value;

  if (!allowAlcohol.value) {
    return !skuDetails.containsAlcohol;
  }

  return status?.saleStatus === SalesStatus.FOR_SALE;
});

const buttonLabel = computed(() => {
  if (canBeAdded.value) return t('ADD');

  return !allowAlcohol.value ? t('CONTAINS_ALCOHOL') : t('NOT_AVAILABLE');
});

function addItem() {
  if (containsAlcohol.value) {
    emit('showAlcoholConfirm');
    return;
  }

  if (canBeAdded.value) emit('addItem');
}
</script>

<template>
  <button
    type="button"
    class="card-container"
    :disabled="!canBeAdded"
    :class="[
      simple ? 'shadow-none h-auto p-0' : 'shadow-lg p-5 h-[500px]',
      { 'opacity-60': !canBeAdded },
    ]"
    @click="addItem()"
  >
    <ItemsInCartFlag v-if="alreadyAddedItem" :items-in-cart="itemsInCart" />

    <div class="relative">
      <span v-if="!canBeAdded && !containsAlcohol" class="out-of-stock">{{ $t('PRODUCTS.OUT_OF_STOCK') }}</span>
      <span v-if="!canBeAdded && containsAlcohol" class="flex justify-center out-of-stock">
        <img class="w-28" src="/age-restriction.svg" alt="age-restriction" />
      </span>
      <KioskImage
        hide-border
        :image-class="`h-56 w-56 object-cover object-center rounded-md ${canBeAdded ? '' : 'opacity-50'}`"
        :src="productImage"
        :alt="product.name"
      />
    </div>

    <div class="flex flex-col items-center">
      <span class="product-name">
        {{ product.name }}
      </span>
      <span class="product-price">
        {{ currencyPrice }}
      </span>
    </div>

    <KioskButton
      v-if="!simple"
      color="primary"
      text-size="small"
      :class="canBeAdded ? 'px-4' : '!px-3'"
      :disabled="!canBeAdded"
    >
      {{ buttonLabel }}
    </KioskButton>
  </button>
</template>
<style scoped>
.card-container {
  @apply bg-white flex flex-col items-center justify-around w-full relative;
}

.product-price {
  @apply text-[2.5rem] leading-6 tracking-tighter font-normal text-neutral-400 mt-5;
}

.product-name {
  @apply font-bold text-[32px] tracking-tighter leading-8 text-center min-h-[64px];
}

.out-of-stock {
  @apply text-5xl mx-auto w-full left-0 right-0 text-center absolute mt-[50%] -translate-y-1/2 z-10 uppercase;
}
</style>
