<script lang="ts" setup>
import { PropType } from 'vue';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { useScrollableReset } from '@/common/composables';
import { FadeColors } from '@/common/enums/fadeColors';
import { KioskCartItem } from '../interfaces';

const route = useRoute();
const router = useRouter();
const cartStore = useCartStore();
const menuStore = useMenuStore();
const galleryEl = ref<HTMLElement>();
const metadataStore = useMetadataStore();
const { alcohol } = storeToRefs(menuStore);
const showAlcoholConfirm = ref<boolean>(false);
const { priceDecimals, cardRadiusClass } = storeToRefs(metadataStore);
const { triggerSelectItem, triggerAddToCart } = useGTMEventsComposable();

const props = defineProps({
  category: {
    type: Object as PropType<HubsterCategory>,
    required: true,
  },
  productItems: {
    type: Array as PropType<HubsterMenuPublishItem[]>,
    required: true,
  },
});

const selectedItem = ref<HubsterMenuPublishItem>();

function setSelectedProduct(item: HubsterMenuPublishItem) {
  selectedItem.value = item;
}

function addItemToCart(item: HubsterMenuPublishItem) {
  const newProduct: KioskCartItem = {
    ...item,
    price: item.price.amount,
    quantity: 1,
    modifiers: [],
    note: item.photoIds[0],
    // Check
    localId: '',
    categoryId: props.category.id,
    categoryName: props.category.name,
  };

  cartStore.addCartItem(newProduct);

  triggerAddToCart({
    product: newProduct,
    modifiers: [],
    totalPrice: item.price.amount,
    category: props.category,
    section: route.name,
    quantity: 1,
    identifier: cartStore.items.at(-1)?.identifier ?? '',
  });
}

function addItem(item: HubsterMenuPublishItem) {
  if (item.skuDetails.containsAlcohol && !alcohol.value.checked) {
    showAlcoholConfirm.value = true;

    return;
  }

  // Trigger GTM events
  triggerSelectItem(item, props.category);

  if (item.modifierGroupIds.length === 0) {
    addItemToCart(item);
    return;
  }

  router.push({
    name: 'OrderCustomizationView',
    params: { itemId: item.id, categoryId: props.category.id },
  });
}

function denyAlcoholProducts() {
  selectedItem.value = undefined;
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ allow: false });
}

function confirmProductSelection() {
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ checked: true });
  // Add item to cart
  if (selectedItem.value) addItem(selectedItem.value);
}

function openAlcoholConfirm(item: HubsterMenuPublishItem) {
  setSelectedProduct(item);

  if (alcohol.value.checked) {
    confirmProductSelection();
    return;
  }
  // Show alcohol confirm
  showAlcoholConfirm.value = true;
}

onMounted(() => {
  if (props.productItems.length > 0 && galleryEl.value) {
    useScrollableReset(galleryEl.value);
  }
});
</script>

<template>
  <ScrollContainer tw-class="flex-grow" :color="FadeColors.BACKGROUND" height="calc(100% - 7rem)" width="100%">
    <section
      :key="category.id"
      ref="galleryEl"
      class="grid content-start grid-cols-3 gap-6 p-6 justify-items-center animate__animated animate__slideInLeft"
    >
      <CatalogItem
        v-for="product in productItems"
        :key="product.id"
        :product="product"
        :price-decimals="priceDecimals"
        :allow-alcohol="alcohol.allow"
        :class="cardRadiusClass"
        @add-item="addItem(product)"
        @show-alcohol-confirm="openAlcoholConfirm(product)"
      />
    </section>
  </ScrollContainer>

  <AlcoholConfirm
    v-if="showAlcoholConfirm"
    @cancel="denyAlcoholProducts()"
    @confirm="confirmProductSelection()"
  />
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: ease .2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
