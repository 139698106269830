import { HubsterMenuPublishData } from '@slabcode/hubster-models/hubster/payloads/menu/publish';
import { HubsterMenuModifierGroup } from '@slabcode/hubster-models/hubster/common/menu';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';
import { HubsterMenuModifierWithImage, ModifierGroup } from '../../modules/orders/interfaces';

type ParseModifierProps = {
  menu: HubsterMenuPublishData;
  modifierGroupsIds: string[];
  defaultModifiers?: boolean;
  directionState?: number[];
};

/**
 * Define the default initial values per each modifier
 *
 * @param {HubsterMenuModifierGroup} modifier the current modifier
 * @param {boolean} defaultModifiers validate if has default modifiers according to the deep level
 * @param {number} index Validate if its the first
 */
function defaultModifierSelection(
  modifier: HubsterMenuModifierGroup,
  defaultModifiers: boolean,
  index: number,
) {
  // If its an optional field not set default value
  if (modifier.minimumSelections === 0) {
    return 0;
  }

  if (modifier.name.includes('Upgrade')) {
    return 0;
  }

  // Check if default index is customizable
  if (index === 0 && (modifier.itemIds.length === 1)) {
    const { maximumSelections, minimumSelections } = modifier;
    return (maximumSelections === minimumSelections) ? maximumSelections : minimumSelections;
  }

  return index === 0 && defaultModifiers ? modifier.minimumSelections : 0;
}

/**
 * this parse the modifier ids {string[]} to modifier objects with its corresponding items
 * @param menu is the current hubster menu
 * @param modifierGroupsIds this take modifiersIds for organice them
 */
export const parseModifier = ({
  menu,
  modifierGroupsIds,
  defaultModifiers = true,
  directionState,
}: ParseModifierProps) => modifierGroupsIds.map((modifierId, index) => {
  const modifier = menu.modifierGroups[modifierId];
  const direction = directionState ? [...directionState, index] : [index];
  if (!menu.modifierGroups[modifierId]) {
    // eslint-disable-next-line no-console
    console.warn(`Modifier "${modifierId}" not found in menu modifierGroups`);
    return null;
  }
  // eslint-disable-next-line no-use-before-define
  const items = parseModifierItems(menu, modifier, defaultModifiers, direction);

  return {
    ...modifier,
    direction,
    items,
  };
}).filter((m) => m !== null) as ModifierGroup[];

function parseDeepModifier(modifierItem: HubsterMenuPublishItem, menu: HubsterMenuPublishData, direction: number[]) {
  let deepModifiers: ModifierGroup[] = [];

  if (modifierItem.modifierGroupIds.length > 0) {
    deepModifiers = parseModifier({
      menu,
      modifierGroupsIds: modifierItem.modifierGroupIds,
      defaultModifiers: false,
      directionState: direction,
    });
  }

  return deepModifiers;
}

/**
 * Parse nested items
 *
 * @param {HubsterMenuPublishData} menu is the current hubster menu
 * @param {HubsterMenuModifierGroup} modifier current modifier
 * @param {boolean} defaultModifiers define if has default modifiers
 */
function parseModifierItems(
  menu: HubsterMenuPublishData,
  modifier: HubsterMenuModifierGroup,
  defaultModifiers: boolean,
  direction: number[],
) {
  if (modifier.itemIds.length === 0) return [];

  return modifier.itemIds
    // the sort always should be the first to use the correct 'direction'
    .sort((itemIdA, itemIdB) => {
      const modifierItemA = menu.items[itemIdA];
      const modifierItemB = menu.items[itemIdB];
      if (modifierItemA.status.saleStatus === SalesStatus.FOR_SALE && modifierItemB.status.saleStatus !== SalesStatus.FOR_SALE) {
        return -1; // a should come before b
      }
      if (modifierItemA.status.saleStatus !== SalesStatus.FOR_SALE && modifierItemB.status.saleStatus === SalesStatus.FOR_SALE) {
        return 1; // b should come before a
      }
      return 0; // keep the original order for both having same status
    })
    .map((itemId, deepIndex) => {
      const modifierItem = menu.items[itemId];
      const newDirection = [...direction, deepIndex];
      if (!modifierItem) return undefined;
      const deepModifiers = parseDeepModifier(modifierItem, menu, newDirection);
      // Check availability
      return {
        ...modifierItem,
        direction: newDirection,
        groupId: modifier.id,
        price: modifierItem.price.amount,
        image: modifierItem.photoIds[0] ?? '',
        modifiersGroups: deepModifiers,
      };
    })
    .filter((m) => m !== undefined)

    .map((m, i) => ({
      ...m,
      quantity: defaultModifierSelection(modifier, defaultModifiers, i),
    })) as HubsterMenuModifierWithImage[];
}
