<script setup lang="ts">
import { ButtonType } from '@/common/types';

const props = defineProps({
  disableIncrease: {
    type: Boolean,
    default: false,
  },
  buttonStyle: {
    type: String,
    required: false,
    default: 'fill',
  },
  buttonClass: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

// https://vuejs.org/api/sfc-script-setup.html#definemodel
const model = defineModel({
  type: Number,
  required: true,
  validator: (value: number) => value >= 0,
});

const buttonColors = computed((): Record<string, ButtonType> => {
  const isFill = props.buttonStyle === 'fill';

  return {
    decrease: 'default',
    add: isFill ? 'primary' : 'outline',
  };
});

/**
 * Decrement counter value and emits
 * 'update:modelValue' event with this value
 */
function decrease(): void {
  if (model.value <= 0) return;
  // Update counter
  model.value -= 1;
}

/**
 * Increment counter value and emits
 * 'update:modelValue' event with this value
 */
function add(): void {
  // Update counter
  model.value += 1;
}
</script>

<template>
  <div
    :class="{
      'opacity-50': disabled,
    }"
    class="kfc-counter"
  >
    <KioskButton
      type="button"
      class="counter-button !border-black"
      :disabled="model === 0 || disabled"
      :color="buttonColors.decrease"
      @click="decrease()"
    >
      <span class="text-xl icon icon-minus" />
    </KioskButton>

    <span
      class="w-8 text-3xl font-medium text-center"
      :class="{ 'w-14 h-14 px-4 flex items-center justify-center rounded-xs border-2 border-neutral-200/50': buttonStyle === 'outline' }"
    >
      {{ model }}
    </span>

    <KioskButton
      :color="buttonColors.add"
      type="button"
      class="counter-button"
      :disabled="disableIncrease || disabled"
      @click="add()"
    >
      <span class="text-xl icon icon-plus" />
    </KioskButton>
  </div>
</template>

<style>
.kfc-counter {
  @apply flex items-center gap-4 p-4 text-2xl;
}

.counter-button {
  @apply btn !border-2 !h-12 !w-12 !min-h-12 !p-2.5 text-4xl font-bold;
}
</style>
