import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishData } from '@slabcode/hubster-models/hubster/payloads/menu/publish';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import requests from '../common/plugins/axios';
import { parseModifier } from '../common/utils/parseModifier';
import { AlcoholStatus } from '@/modules/orders/interfaces';
import { useLanguageStore } from '@/modules/language/stores/languageStore';

export type MenuState = {
  storeId: string;
  menu: HubsterMenuPublishData | null;
  currentCategory: HubsterCategory | null;
  voucherProduct: HubsterMenuPublishItem | null;
  hiddenCategories: string[];
  integration: string;
  alcohol: AlcoholStatus;
};

const INITIAL_STATE: MenuState = {
  menu: null,
  storeId: '',
  currentCategory: null,
  voucherProduct: null,
  hiddenCategories: ['COUPON [DO NOT SHOW]'],
  integration: '',
  alcohol: { allow: true, checked: false },
};

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({ ...INITIAL_STATE }),

  getters: {
    categories: (state): HubsterCategory[] => {
      if (state.menu === null) return [];

      const [storeData] = Object.values(state.menu.menus);
      if (!storeData) return [];

      return storeData.categoryIds
        .map((c) => state.menu?.categories[c] as HubsterCategory)
        .filter((c) => !state.hiddenCategories.includes(c?.name));
    },

    upSellingBanners: (state): HubsterMenuPublishItem[] => {
      const { menu } = state;
      if (!menu) return [];
      const { items } = menu;
      return Object.keys(items)
        .map((itemId) => menu.items[itemId])
        .filter((item) => item.photoIds[1]);
    },

    products: (state) => {
      const { currentCategory, menu } = state;

      if (!currentCategory || !menu) return [];

      return currentCategory.itemIds
        .map((itemId) => menu.items[itemId])
        .filter((item) => Boolean(item));
    },

    productsCurrency: (state) => {
      if (!state.menu) return 'USD';

      // const firstItem = Object.values(state.menu.items)[0];

      // return firstItem.price.currencyCode;
      return 'USD';
    },

    // TODO: Check best way
    voucherProducts: (state): HubsterMenuPublishItem[] => {
      if (!state.menu) return [];

      const [storeData] = Object.values(state.menu.menus);
      if (!storeData) return [];

      const [vouchersCategory] = storeData.categoryIds
        .map((c) => state.menu?.categories[c] as HubsterCategory)
        .filter((c) => state.hiddenCategories.includes(c.name));
      if (!vouchersCategory) return [];

      return vouchersCategory.itemIds.map((itemId) => state.menu!.items[itemId]);
    },
  },

  actions: {
    /**
     * Get the api menu and cache it
     * @returns {Promise<void>}
     */
    async getMenu(storeId: string): Promise<void> {
      const languageStore = useLanguageStore();
      const response: HubsterMenuPublishData = await requests.get(
        `${storeId}/menus`,
        {
          params: {
            timestamp: new Date().toLocaleString('sv'),
            language: languageStore.currentLanguage.toUpperCase(),
          },
        },
      );
      // Set storeId
      this.storeId = storeId;
      // Has been changed
      if (response) {
        this.setMenuData(response);
      }
    },

    setMenuData(data: HubsterMenuPublishData): void {
      // Set menu
      this.menu = data;
    },

    /**
     *
     * @param categoryId represent the category to display corresponding items in the cart
     */
    selectCategory(categoryId: string) {
      if (!this.menu) return;

      this.currentCategory = this.menu.categories[categoryId];
    },

    /**
     * Get the menu item by id
     *
     * @param {string} id that the item that will be searched
     */
    getItemById(id: string) {
      return this.menu?.items[id];
    },

    /**
     * This function create new modifiers with quantity and change
     * add the price and image
     *
     * @param {(string | null)} itemId the item that will be update
     */
    getModifiersByItem(itemId: string | null, defaultModifiers = true) {
      const { menu } = this.$state;

      if (!menu || !itemId) return [];

      const item = menu.items[itemId];

      return parseModifier({
        menu,
        modifierGroupsIds: item.modifierGroupIds,
        defaultModifiers,
      });
    },

    /**
     * Define the table number from table view
     * @param {string} tableNumber the table number
     */
    // setTableNumber(tableNumber: string) {
    //   this.$patch({ ...this.$state, tableNumber });
    // },

    /**
     * allow to change fulfillmentMode
     * @param fulfillmentMode new delivery type
     */
    // changeFulfillmentMode(fulfillmentMode: FulfillmentMode) {
    //   this.fulfillmentMode = fulfillmentMode;
    // },

    resetVoucherProduct() {
      this.voucherProduct = null;
    },

    findVoucherProductById(description: string) {
      const index = this.voucherProducts.findIndex((p) => p.description === description);
      // Set value
      this.voucherProduct = (index !== -1) ? this.voucherProducts[index] : null;
    },
    // action#1|code#LKIDZAN223|object#023132

    setIntegration(value: string) {
      this.integration = value;
    },

    setAlcoholValue(option: Partial<AlcoholStatus>) {
      Object.assign(this.alcohol, option);
    },

    reset() {
      this.$patch({ ...INITIAL_STATE });
      // Force alcohol reset
      this.alcohol = { allow: true, checked: false };
    },
  },
});

export default useMenuStore;
