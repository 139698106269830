<script lang="ts" setup>
import { between, integer, required } from '@vuelidate/validators';
import { router } from '@/router';

const showKeyboard = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

const rules = {
  tableNumber: {
    required,
    integer,
    betweenValue: between(0, 100),
  },
};

const orderTypeStore = useOrderTypeStore();
const { tableNumber } = storeToRefs(orderTypeStore);
const v$ = useVuelidate(rules, { tableNumber });

/**
 * Check if input receives an invalid value to prevent update
 * @param event - Keyboard event
 */
function updateTableInput(event: KeyboardEvent) {
  // Prevent double space
  if (event.code === 'Space') {
    event.preventDefault();
    return;
  }

  if (!['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
    if (!Number.isFinite(+event.key)) event.preventDefault();
  }
}

/**
 * Calls router to redirect to Categories view.
 */
function goToCategories(skip = false) {
  if (!skip) orderTypeStore.setTableNumber(tableNumber.value);
  router.push({ name: 'OrderCategoriesView' });
}

/**
 * Set 'tableNumber' value to null and
 * calls 'goToCategories' method.
 */
function skipTable() {
  orderTypeStore.setTableNumber('');
  // Make redirect
  goToCategories(true);
}

// TODO: Make unit test
watch(tableNumber, (newValue) => {
  if (newValue.includes('.')) {
    tableNumber.value = tableNumber.value.replace('.', '');
  }
});
</script>

<template>
  <span class="kfc-text text-[40px]">{{ $t("TABLE_NUMBER") }}</span>

  <div
    class="flex gap-5 bg-white items-center shadow-2xl rounded-xs p-10 w-[420px]"
  >
    <label for="tableNumber">
      <span class="icon icon-table-bar mr-5 text-[60px]" />
    </label>

    <input
      v-model="tableNumber"
      class="table-input"
      id="tableNumber"
      min="0"
      max="100"
      placeholder="00"
      maxlength="2"
      ref="inputRef"
      @keydown="updateTableInput($event)"
      @focus="showKeyboard = true"
    />
  </div>

  <KioskButton
    :disabled="v$.$invalid"
    class="next-button"
    color="primary"
    @click="goToCategories()"
  >
    {{ $t("NEXT") }}
  </KioskButton>

  <div class="separator w-[346px] h-0.5 bg-neutral-400 my-4" />

  <KioskButton
    text-size="large"
    class="option-button !min-w-[420px] max-w-[420px] !leading-[50px]"
    @click="skipTable()"
  >
    {{ $t("PICK_UP_COUNTER") }}
  </KioskButton>

  <SlideTransition
    enter-animation="fadeInUp"
    leave-animation="fadeOutDown"
  >
    <NumbersKeyboard
      v-if="showKeyboard"
      class="absolute bottom-0 z-20 w-1/2 p-10"
      v-model="tableNumber"
      :input-ref="inputRef"
      :max-length="2"
      @close="showKeyboard = false"
    />
  </SlideTransition>
</template>
<style scoped>
.table-input {
  @apply bg-neutral-200 text-7xl text-center w-full focus:outline-none;
}

.next-button {
  @apply w-[420px] h-[120px] p-5 text-7xl flex items-center justify-center disabled:bg-opacity-30;
}
</style>
