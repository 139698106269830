<script setup lang="ts">
import { PropType } from 'vue';
import { NestedModifiersLayouts } from '@slabcode/kiosks-core/enums';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';
import { HubsterMenuModifierWithImage, ModifierChange, ModifierGroup } from '../interfaces';

const props = defineProps({
  modifier: {
    type: Object as PropType<ModifierGroup>,
    required: true,
  },
  depth: {
    type: Number,
    required: true,
  },
  modifierParent: {
    type: Object as PropType<HubsterMenuModifierWithImage>,
    default: null,
    required: false,
  },
  selectedItems: {
    type: Array as PropType<HubsterOrderModifier[]>,
    required: false,
    default: () => [],
  },
  priceDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
  itemsStyle: {
    type: String as PropType<NestedModifiersLayouts>,
    required: false,
    default: NestedModifiersLayouts.Grid,
  },
  counterStyle: {
    type: String,
    required: false,
    default: 'fill',
  },
});

const emit = defineEmits(['counterChanged']);
const modifiersStore = useModifierStore();
const { itemsStyle, modifier, modifierParent, selectedItems } = toRefs(props);

const OrderModifierItem = defineAsyncComponent(() => import('@/modules/orders/components/OrderModifierItem.vue'));
const OrderModifierListItem = defineAsyncComponent(() => import('@/modules/orders/components/OrderModifierListItem.vue'));

const childComponent = computed(() => ((itemsStyle.value === NestedModifiersLayouts.Grid) ? OrderModifierItem : OrderModifierListItem));

// TODO: Refactor in method
const maxSelections = computed(
  () => {
    const parentQuantity = props.modifierParent?.quantity ?? 1;
    const res = parentQuantity * props.modifier.maximumSelections;
    return res;
  },
);

const maxPerItem = computed(
  () => {
    const parentQuantity = props.modifierParent?.quantity ?? 1;
    const res = parentQuantity * props.modifier.maxPerModifierSelectionQuantity;
    return res;
  },
);

/**
 * Detect when the counter changed
 * @param {ModifierChange} data Info thats required to update the counter
 */
function counterChanged(data: ModifierChange) {
  emit('counterChanged', {
    data,
    modifierId: modifier.value.id,
  });
}

function toggleCounter(data: ModifierChange) {
  const { item, type } = data;

  const parentData = {
    quantity: item.quantity === 1 ? 0 : 1,
    item,
    type,
  };

  emit('counterChanged', {
    data: parentData,
    modifierId: modifier.value.id,
  });
}

function hasBeenSelected(id: string, gId: string) {
  return selectedItems.value.some((m) =>
    ((m.id === id) && (m.groupId === gId))
    || m.modifiers?.some((dm) => dm.id === id));
}

function updateOnImage(data: ModifierChange) {
  if (data.quantity > modifier.value.maximumSelections) return;
  counterChanged(data);
}

function getModifierClass(item: HubsterMenuModifierWithImage) {
  const isGridMode = itemsStyle.value === NestedModifiersLayouts.Grid;
  const isCountItem = modifier.value.maximumSelections > 1;
  const counterClass = isCountItem ? '' : 'py-2';

  if (item.quantity === 0) return (isGridMode || isCountItem) ? '' : 'py-2';

  const selectedValue = 'border-primary-400 shadow-lg shadow-neutral-500/30';

  if (!isGridMode) return `${selectedValue} ${counterClass}`;

  return selectedValue;
}

function decreaseModifiersDepth() {
  modifiersStore.currentDepth -= 1;
}

function increaseModifiersDepth() {
  modifiersStore.currentDepth += 1;
}
</script>

<template>
  <!-- TODO: Change logic -->
  <template v-if="maxSelections === 1">
    <component
      v-for="item in modifier.items"
      :key="item.id"
      :is="childComponent"
      :modifier-parent="modifierParent"
      :modifier="item"
      :container-class="getModifierClass(item)"
      :modifier-decimals="priceDecimals"
      :depth="depth + 1"
      :show-deep-modal="modifiersStore.currentDepth === depth"
      @close="decreaseModifiersDepth()"
      @customize="increaseModifiersDepth()"
      @click="toggleCounter({ quantity: 1, item, type: 'only' })"
    />
  </template>

  <template v-if="(maxSelections > 1) && (maxPerItem > 1)">
    <component
      v-for="item in modifier.items"
      :is="childComponent"
      :modifier="item"
      :modifier-parent="modifierParent"
      :key="item.id"
      :modifier-decimals="priceDecimals"
      :container-class="getModifierClass(item)"
      :depth="depth + 1"
      :show-deep-modal="modifiersStore.currentDepth === depth"
      @close="decreaseModifiersDepth()"
      @customize="increaseModifiersDepth()"
      @click="updateOnImage({
        quantity: item.quantity + 1,
        item,
        type: 'count',
      })"
    >
      <span
        class="flex justify-center w-full text-4xl font-medium"
        v-if="
          modifier.items.length === 1
            && modifier.minimumSelections === maxSelections
        "
      >
        {{ item.quantity }}
      </span>

      <KioskCounter
        v-else
        class="mx-auto"
        :disabled="item.status.saleStatus !== SalesStatus.FOR_SALE"
        :model-value="item.quantity"
        :button-style="counterStyle"
        :disable-increase="item.quantity === maxPerItem"
        @update:model-value="counterChanged({
          quantity: $event,
          item,
          type: 'count',
        })"
      />
    </component>
  </template>

  <template v-if=" maxSelections > 1 && maxPerItem === 1">
    <component
      v-for="item in modifier.items"
      :is="childComponent"
      :modifier="item"
      :modifier-parent="modifierParent"
      :key="item.id"
      :modifier-decimals="priceDecimals"
      :container-class="getModifierClass(item)"
      :depth="depth + 1"
      :show-deep-modal="modifiersStore.currentDepth === depth"
      @close="decreaseModifiersDepth()"
      @customize="increaseModifiersDepth()"
      @click="counterChanged({
        quantity: +(!hasBeenSelected(item.id!, item.groupId!)),
        item,
        type: 'checkbox',
      })"
    >
      <label :for="item.id" />

      <input
        type="checkbox"
        class="h-7 accent-primary-600"
        :class="(itemsStyle === NestedModifiersLayouts.Grid) ? 'w-full' : 'w-7'"
        :id="item.id"
        :checked="hasBeenSelected(item.id!, item.groupId!)"
        @click="counterChanged({
          quantity: +(!hasBeenSelected(item.id!, item.groupId!)),
          item,
          type: 'checkbox',
        })"
      />
    </component>
  </template>
</template>
