<script setup lang="ts">
import { SelectOption } from '@/common/interfaces';
import { goBackToHome } from '@/common/utils';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const menuStore = useMenuStore();
const cartStore = useCartStore();
const webhookStore = useWebhookStore();
const inactiveStore = useInactiveStore();
const metadataStore = useMetadataStore();
const settingsStore = useSettingsStore();
const { triggerCancelIdleModal, triggerCancelOrder, triggerIdleModal, triggerStartOrder } = useGTMEventsComposable();

const { items } = storeToRefs(cartStore);
const { gtmTransactionId } = storeToRefs(settingsStore);
const { showPaginatedMG, timelineImage } = storeToRefs(metadataStore);

// TODO: Move to step store
const showTimeline = computed(() => showPaginatedMG.value && route.name !== 'OrderCustomizationView');

const timelineSteps = computed((): SelectOption[] => {
  const steps = [
    t('TIMELINE.CHOOSE_PRODUCT'),
    t('TIMELINE.CUSTOMIZATION'),
    t('TIMELINE.CONFIRM'),
    t('TIMELINE.PAY'),
  ];

  if (showPaginatedMG.value) steps.splice(1, 1);

  return steps.map((i) => ({ label: i, value: i }));
});

const totalItems = computed(
  () => addLeftZero(items.value.reduce((acc, curr) => acc + curr.quantity, 0)),
);

// Logic to set timeline data based on current route
const timelineOptions = computed(() => {
  const { name } = route;
  const defaultOptions = { position: -1, class: 'hidden' };
  const stepRoutes = [
    'OrderProductsView',
    'OrderCustomizationView',
    'OrderCartView',
    'OrderCheckoutView',
  ];

  if (showPaginatedMG.value) stepRoutes.splice(1, 1);

  if (name && stepRoutes.includes(name.toString())) {
    const position = stepRoutes.findIndex((r) => r === name);
    const initialClass = 'w-4/5 absolute right-0';
    const stickyClass = `sticky top-0 w-full flex bg-neutral-200 justify-center items-center pb-${showTimeline ? '0' : '8'} z-20`;
    // Calc step and style
    return {
      position,
      class: position === 0 ? initialClass : stickyClass,
    };
  }

  return defaultOptions;
});

function redirectToCart() {
  router.push({ name: 'OrderCartView' });
}

/**
 * Reset the cart and redirect to first view
 */
function restartCart(isModal = false) {
  triggerCancelOrder({
    items: cartStore.items,
    section: route.name!.toString(),
    isModal,
  });

  cartStore.clearCart();
  webhookStore.restartWebhook();

  if (settingsStore.multibrandData) {
    const { backUrl } = settingsStore.multibrandData;
    window.location.href = backUrl;
    return;
  }

  goBackToHome();
  // Reset transactionId
  gtmTransactionId.value = '';
}

function cancelInactivityModal() {
  inactiveStore.resetCountdown('timer');
  // Trigger GTM event
  triggerCancelIdleModal(route.name);
}

/**
 * Activate the inactive modal
 */
async function beforeMount() {
  const { storeId } = route.params;
  if (!storeId) return;

  await menuStore.getMenu(storeId as string);
  // Geneate transactionId
  gtmTransactionId.value = new Date().getTime().toString();
  triggerStartOrder();
  // Start countdown
  inactiveStore.resetCountdown('timer');
  inactiveStore.startCountdown();
}

function beforeUnmount() {
  restartCart();
}

onBeforeMount(() => beforeMount());
onBeforeUnmount(() => beforeUnmount());

const showHeaderSteps = computed(() => {
  if (!showPaginatedMG.value) return true;

  return (route.name !== 'OrderCustomizationView');
});

watch(() => inactiveStore.timer, async (newValue) => {
  if (newValue <= 0) {
    inactiveStore.resetCountdown('screen');
    inactiveStore.startCountdown();
    // Trigger GTM event
    triggerIdleModal(route.name);
  }
});
</script>

<template>
  <section :class="timelineOptions.class">
    <div
      v-if="showHeaderSteps"
      class="flex items-center w-full flex-nowrap animate__animated animate__fadeInDown"
      :class="(timelineOptions.position !== 0) ? 'px-12' : 'px-6'"
    >
      <KioskTimeline
        v-if="timelineImage"
        :steps="timelineSteps"
        :current-index="timelineOptions.position"
        :active-image="timelineImage"
      />

      <KioskButton
        @click="redirectToCart()"
        color="primary"
        class="flex-none !h-[68px] w-[108px] !py-2"
      >
        <div class="flex items-center justify-center text-[32px] gap-1">
          <span class="icon icon-shopping-cart" />
          <span class="flex items-center justify-center p-1 text-black bg-white h-9 w-9 rounded-xs">{{ totalItems }}</span>
        </div>
      </KioskButton>
    </div>
  </section>

  <router-view />

  <Teleport to="#inactive-modal">
    <OrderInactivityModal
      v-if="inactiveStore.currentKey === 'screen'"
      :time="inactiveStore.screen"
      @inactive="restartCart(true)"
      @close="cancelInactivityModal()"
    />
  </Teleport>
</template>
