<script setup lang="ts">
import { PropType } from 'vue';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { SelectOption } from '@/common/interfaces';
import { CollapsableModifierGroup, ModifierChange } from '@/modules/orders/interfaces';

const props = defineProps({
  modifier: {
    type: Object as PropType<CollapsableModifierGroup>,
    required: true,
  },
  selectedItems: {
    type: Array as PropType<HubsterOrderModifier[]>,
    required: true,
  },
  modifierSelections: {
    type: Number,
    required: true,
  },
  totalSteps: {
    type: Number,
    required: true,
  },
  isCurrentValid: {
    type: Boolean,
    required: true,
  },
  stepIndex: {
    type: Number,
    required: true,
  },
  isEdit: {
    type: Boolean,
    required: true,
  },
  priceDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
  counterStyle: {
    type: String,
    required: false,
    default: 'fill',
  },
  showFullAnimation: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  counterChanged: [{ data: ModifierChange, modifierId: string }],
  goToStep: [index: number],
  cancel: [],
  save: [],
}>();

const { t } = useI18n();
const wrapper = ref<HTMLDivElement>();
const showCatalogModal = ref<boolean>(false);
const { isEdit, modifier, selectedItems, stepIndex, totalSteps } = toRefs(props);

const steps = computed((): SelectOption[] => {
  if (totalSteps.value === 0) return [];

  return [...Array(totalSteps.value)].map((_m, i) => ({
    label: (i + 1).toString(),
    value: (i + 1),
  }));
});

const nextButtonLabel = computed((): string => {
  if (stepIndex.value !== (steps.value.length - 1)) return t('NEXT');

  return isEdit.value ? t('SAVE') : t('ADD');
});

/**
 * Check index value and trigger expected event.
 * @param {number} index - Next step index
 */
function goToStep(index: number) {
  if (index === totalSteps.value) emit('save');
  else emit('goToStep', index);
}

watch(stepIndex, () => {
  if (wrapper.value) {
    wrapper.value.scrollTop = 0;
    wrapper.value.scrollIntoView({ behavior: 'smooth' });
  }
});
</script>

<template>
  <div class="overflow-hidden customization-list">
    <KioskTimeline
      hide-labels
      :steps="steps"
      :current-index="stepIndex"
    />

    <div class="step-header text-[34px] uppercase flex items-center px-12 mt-12">
      <h4 class="flex-none name">
        {{ modifier.name }}
      </h4>

      <div class="grow h-[1px] bg-neutral-500 mx-8" />

      <OrderModifierCounter
        :show-full-animation="showFullAnimation"
        :min-selections="modifier.minimumSelections"
        :current-selections="modifierSelections"
        :max-selections="modifier.maximumSelections"
      />
    </div>

    <section
      ref="wrapper"
      class="overflow-y-auto modifier-container"
    >
      <OrderModifiers
        :modifier="modifier"
        :selected-items="selectedItems"
        :price-decimals="priceDecimals"
        :counter-style="counterStyle"
        :depth="0"
        @counter-changed="emit('counterChanged', $event)"
      />
    </section>
  </div>

  <OrderBottomActions
    back-icon=""
    :back-label="$t('BACK')"
    :next-label="nextButtonLabel"
    :disable-back="stepIndex === 0"
    :disable-next="!isCurrentValid"
    @back-pressed="emit('goToStep', stepIndex - 1)"
    @next-pressed="goToStep(stepIndex + 1)"
  >
    <template #prepend>
      <button
        type="button"
        class="w-1/5 h-20 text-4xl text-black uppercase btn btn-ghost"
        @click="showCatalogModal = true"
      >
        {{ $t('MAIN_MENU') }}
      </button>
    </template>
  </OrderBottomActions>

  <ConfirmModal
    v-if="showCatalogModal"
    :title="$t('MODIFIERS_BACK_TITLE')"
    :message="$t('MODIFIERS_BACK_DESCRIPTION')"
    :local-modal="true"
    :success-button-label="$t('TIMELINE.CONFIRM')"
    :deny-button-label="$t('CANCEL')"
    @on-close="showCatalogModal = false"
    @on-ok="emit('cancel')"
  />
</template>

<style scoped>
.modifier-container {
  @apply my-12 px-12 grid grid-cols-3 gap-8 place-content-start justify-items-center h-[1240px];
}

.optional-label {
  @apply text-neutral-400 bg-neutral-100 font-bold px-1 text-4xl rounded h-10 flex justify-center items-center;
}

.counter-span {
  @apply flex justify-center items-start w-20 h-10 leading-10 text-white border-none rounded px-1 font-bold;
}
</style>
