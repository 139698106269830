<script lang="ts" setup>
import { currencies } from '@slabcode/kiosks-core/utils';
import { Currencies } from '@slabcode/kiosks-core/enums';

const metadataStore = useMetadataStore();
const cart = useCartStore();

const { clusterSettings } = storeToRefs(metadataStore);

function getFlag(code: Currencies) {
  if (code in currencies) {
    return currencies[code].flag;
  }
  return null;
}

const currenciesTotals = computed(() => {
  if (!clusterSettings.value?.currency) {
    console.warn('Currency settings not found');
    return [];
  }

  const availableCurrencies = [];
  const total = cart.getTotal();

  availableCurrencies.push({
    flag: getFlag(clusterSettings.value.currency.preferredCurrencyCode),
    code: clusterSettings.value.currency.preferredCurrencyCode,
    total,
  });

  if (!clusterSettings.value.currency.conversions) {
    return availableCurrencies;
  }

  const conversions = clusterSettings.value.currency.conversions.map((conversion) => {
    const convertedTotal = (total * conversion.rate).toFixed(2);

    return {
      flag: getFlag(conversion.code),
      code: conversion.code,
      total: convertedTotal,
    };
  });

  return [...availableCurrencies, ...conversions];
});

const emit = defineEmits(['closeModal', 'currencySelected']);

function chooseCurrency(code: string) {
  emit('currencySelected', code);
}
</script>

<template>
  <KioskModal modal-class="!w-auto !px-10">
    <div class="flex flex-col items-center gap-8">
      <h2>
        {{ $t('CURRENCY.MODAL_TITLE') }}
      </h2>

      <section class="grid grid-cols-2 gap-6">
        <button v-for="conversion in currenciesTotals" :key="conversion.code" class="currency-button" type="button" @click="chooseCurrency(conversion.code)">
          <img
            v-if="conversion.flag"
            class="rounded-lg w-28"
            :src="conversion.flag"
            :alt="conversion.code"
          />
          {{ conversion.total }} {{ conversion.code }}
        </button>
      </section>

      <KioskButton @click="$emit('closeModal')">
        <span
          class="block mr-4 icon"
          :class="`icon-redo`"
        />
        {{ $t('BACK') }}
      </KioskButton>
    </div>
  </KioskModal>
</template>

<style scoped>
h2 {
  @apply leading-7 tracking-tight font-bold text-4xl;
}

.currency-button {
  @apply w-[304px] h-[348px] flex flex-col items-center justify-center gap-5 bg-[#F9F9F9] text-3xl leading-6 tracking-tight;
}
</style>
