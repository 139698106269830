import { useScroll, watchDebounced } from '@vueuse/core';

export function useScrollableReset(el: HTMLElement) {
  const { isScrolling, y } = useScroll(el);
  const inactiveStore = useInactiveStore();

  watchDebounced(y, (oldV, newV) => {
    if (oldV !== newV) {
      inactiveStore.resetCountdown('timer');
    }
  }, { debounce: 500 });

  return { isScrolling };
}
