<script setup lang="ts">
const orderTypeStore = useOrderTypeStore();
const metadataStore = useMetadataStore();
</script>

<template>
  <span class="kfc-text text-[40px] leading-6">{{ $t('SELECT_ORDER_TYPE') }}</span>

  <div class="flex flex-col order-types">
    <KioskButton
      v-for="(option, index) in orderTypeStore.orderOptions"
      :key="index"
      :icon="option.icon"
      class="py-8 my-5"
      color="outline"
      text-size="large"
      @click="orderTypeStore.setOrderTypeValue(option, metadataStore.hideTableNumber)"
    >
      {{ option.label }}
    </KioskButton>
  </div>
</template>
