<script lang="ts" setup>
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { goBackToHome } from '@/common/utils';
import { useGTMEventsComposable } from '@/common/composables/gtmEvents';

const route = useRoute();
const cartStore = useCartStore();
const menuStore = useMenuStore();
const modalStore = useModalStore();
const metadataStore = useMetadataStore();
const settingsStore = useSettingsStore();

const { colors } = storeToRefs(metadataStore);
const { categories, currentCategory } = storeToRefs(menuStore);
const { triggerCancelOrder, triggerViewItemList } = useGTMEventsComposable();

function cancelOrder() {
  triggerCancelOrder({
    items: cartStore.items,
    section: route.name!.toString(),
    isModal: false,
  });

  cartStore.clearCart();
  modalStore.closeModal();

  if (settingsStore.multibrandData) {
    const { cancelUrl } = settingsStore.multibrandData;
    window.location.href = cancelUrl;
    return;
  }

  goBackToHome();
}

function selectCategory(category: HubsterCategory) {
  menuStore.selectCategory(category.id);
  // Trigger GTM event
  triggerViewItemList(category);
}
</script>

<template>
  <section
    class="flex full-hd"
    :style="{ background: colors?.background }"
  >
    <OrderMenu
      v-if="currentCategory"
      class="w-1/5"
      :menu-items="categories"
      :selected-category="currentCategory"
      @select-category="selectCategory($event)"
    />

    <div class="flex flex-col w-4/5 h-full pt-28">
      <OrderGallery
        :product-items="menuStore.products"
        :category="menuStore.currentCategory!"
      />

      <OrderCart />
    </div>
  </section>

  <ConfirmModal
    v-if="modalStore.showModal"
    modal-class="!w-[588px]"
    :title="$t('CANCEL_ORDER.QUESTION')"
    :message="$t('CANCEL_ORDER.ADVICE')"
    :success-button-label="$t('CANCEL_ORDER.CONFIRMATION')"
    @on-ok="cancelOrder()"
  />
</template>
